// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-container{
    width:92vw !important;
    margin-right: 3.5vw;
    border-radius: 16px;
    padding:1rem 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Banners/banner.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".banner-container{\n    width:92vw !important;\n    margin-right: 3.5vw;\n    border-radius: 16px;\n    padding:1rem 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
