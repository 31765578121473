import React from "react";
import Grid from "@mui/material/Grid";
import "./menu.css";
import BannerSection from "../../components/Banners/BannerSection";
import MobileMenu from "../../components/Menu/navDrawer/mobileMenu/MenuItem/MobileMenuItem";
import socials from "./socials.json";
import YTypoGraphy from "../../components/Text/YTypography/YTypoGraphy";
import { useTranslation } from "react-i18next";

const MenuPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  // Determine the current language direction
  const isEnglish = i18n.language === 'en';

  // Create the menu items dynamically based on the translations
  const menuItemsGuess = [
    {
      id: 1,
      title: t("drawer.account_info"),
      shortDescription: t("drawer.view_and_edit_personal_info"),
      svg: "profile.svg",
      link: "/fa/profile",
    },
    {
      id: 2,
      title: t("drawer.wallet"),
      shortDescription: t("drawer.view_balance_charge_withdraw"),
      svg: "wallet.svg",
      link: "/fa/payment/wallet",
    },
    {
      id: 3,
      title: t("drawer.results"),
      shortDescription: t("drawer.view_lottery_results"),
      svg: "results.svg",
      link: "/fa/lottery/my-results",
    },
    {
      id: 4,
      title: t("drawer.security"),
      shortDescription: t("drawer.set_change_password_2fa"),
      svg: "security.svg",
      link: "/fa/security",
    },
    {
      id: 5,
      title: t("drawer.support"),
      shortDescription: t("drawer.feedback_bug_report_support"),
      svg: "support.svg",
      link: "",
    },
    {
      id: 6,
      title: t("drawer.terms_and_conditions"),
      shortDescription: t("drawer.terms_and_conditions_description"),
      svg: "condition_and_privacy.svg",
      link: "/fa/terms-of-service",
    },
    {
      id: 7,
      title: t("drawer.logout"),
      shortDescription: t("drawer.logout_from_account"),
      svg: "logout.svg",
      link: "",
    },
    {
      id: 8,
      title: t("drawer.return"),
      shortDescription: t("drawer.return_to_previous_page"),
      svg: "return.svg",
      link: "/fa",
    },
  ];

  return (
    <div style={{ direction: isEnglish ? 'ltr' : 'rtl' }}>
      <Grid mt={2}></Grid>

      {menuItemsGuess.map(item => (
        <MobileMenu
          key={item.id}
          ml={16}
          mr={16}
          mb={16}
          icon={<img src={`/images/svgs/menuItem/${item.svg}`} alt="icon" />}
          title={item.title}
          link={item.link}
          description={item.shortDescription}
        />
      ))}

      <BannerSection />

      <Grid container p={4} mr={1}>
        {socials.map(item => (
          <Grid item xs={2} mt={1} key={item.id}>
            <img src={`/images/svgs/menuItem/socials/${item.svg}`} alt="icon" />
          </Grid>
        ))}
      </Grid>

      <Grid container p={2}>
        <Grid item xs={9}>
          <YTypoGraphy variant="h6" color={"white"} fontSize={"0.7rem"} mr={2}>
              {t('footer.madeWithLove')}
          </YTypoGraphy>
        </Grid>
        <Grid item xs={3}>
          <YTypoGraphy variant="h6" color={"white"} fontSize={"0.7rem"} mr={2}>
            {t('footer.version')}
          </YTypoGraphy>
        </Grid>
      </Grid>
    </div>
  );
};

export default MenuPage;
