import React, { useState } from "react";
import { Box, Paper, Card, Grid } from "@mui/material";
import ResultItemNumber from "./../ResultItems/ResultItemNumber";
import YButton from "../../Button/YButton";
import YTypoGraphy from "../../Text/YTypography/YTypoGraphy";
import { t } from "i18next";
import GuessResultItem from "../../Numbers/Guess/GuessPrizeCard/GuessResultItem/GuessResultItem";
import {
  Winner,
  WinnersByEmail,
} from "../../../pages/Lottery/AllResulsts/page";

interface AllResultItemProps {
  date: string;
  luckyNumbers: number[];
  amountWon: number;
  WonCount: number;
  type: string;
  winners: number[];
  winnersEmails: WinnersByEmail;
}

const AllResultItem: React.FC<AllResultItemProps> = ({
  date,
  luckyNumbers,
  amountWon,
  WonCount,
  type,
  winners,
  winnersEmails,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => setExpanded((prev) => !prev);

  return (
    <Paper
      elevation={2}
      sx={{
        backgroundColor: "#FFFFFF1A",
        padding: 0,
        borderRadius: 4,
        maxWidth: "95%",
        margin: "16px auto",
        paddingBottom: "0.5rem",
      }}
    >
      <Box
        display="flex"
        sx={{
          background: "#FFFFFFCC",
          color: "black",
          padding: 1,
          textAlign: "center",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          cursor: "pointer",
        }}
        onClick={toggleExpand}
      >
        <Grid container>
          <Grid item xs={6}>
            <YTypoGraphy textAlign="left" width="58%" color="#2E2E2E">
              {date}
            </YTypoGraphy>
          </Grid>
        </Grid>
      </Box>

      {expanded && (
        <Box p={1}>
          <Card
            sx={{
              background:
                "linear-gradient(90deg, rgba(229, 66, 255, 0.8) 0%, rgba(137, 40, 153, 0.8) 100%)",
              width: "264px",
              height: "76px",
              textAlign: "center",
              margin: "8px auto",
              display: "block",
              borderRadius: "16px",
              color: "white",
            }}
          >
            <YTypoGraphy variant="h5" fontSize="1rem" mt={1}>
              {t("guess_numbers")}
            </YTypoGraphy>

            <Grid container spacing={1} pr={2} pl={2}>
              {luckyNumbers?.length === 0 ? (
                <YTypoGraphy
                  variant="h6"
                  fontSize="1rem"
                  textAlign="center"
                  mt={1}
                  width="100vw"
                >
                  {t("result.not_specified")}
                </YTypoGraphy>
              ) : (
                luckyNumbers
                  ?.slice()
                  .reverse()
                  .map((number, index) => (
                    <Grid item key={index}>
                      <ResultItemNumber
                        luckyNumbers={luckyNumbers}
                        userGuess={[]}
                        defaultColor="white"
                        number={number}
                        index={index}
                        isBlur={false}
                      />
                    </Grid>
                  ))
              )}
            </Grid>
          </Card>

          {winners
            .slice()
            .reverse()
            .map((item, index) => {
              const winCountKey = `win_${6 - index}`; // Create win_1, win_2, ..., win_6 based on index
              return (
                <GuessResultItem
                  key={index}
                  numbers={item}
                  count={6 - index}
                  emails={
                    winnersEmails[winCountKey as keyof WinnersByEmail] || []
                  } // Cast to keyof WinnersByEmail
                />
              );
            })}
        </Box>
      )}
    </Paper>
  );
};

export default AllResultItem;
