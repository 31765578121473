// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media (min-width: 0px) {
    .guessLotteryContainer .MuiGrid-item {
        padding-left: 8px !important;
    }
}

.guessLotteryContainer>h6{
    float: right;
}

.guessLotteryContainer{
    width: 92vw;
    height:auto;
    margin: 8px auto;
    border-radius: 16px;
    border: 1.5px solid #FFA3F6;
}


.guessLotteryContainer .content{
    position: relative;
    overflow-x: hidden;
    text-align: right;
    width: auto;
    height: 66px;
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
    display: block;
    border-radius: 16px;
    border: 0.5px solid;
    background: linear-gradient(90deg, rgba(229, 66, 255, 0.4) 0%, rgba(137, 40, 153, 0.4) 100%);
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;
}


/* .guessLotteryContainer .content{
    position: inherit;
    text-align: right;
    width:100%;
    height: 100%;
    margin-top:0px;
    margin-left:0px;
    backdrop-filter: blur(8px);
    display: block;
    border-radius: 32px;
    background-color:rgba(0, 0, 0, 0.4);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
 */
`, "",{"version":3,"sources":["webpack://./src/components/Numbers/Guess/GuessLotteryCard/GuessLotteryCard.css"],"names":[],"mappings":";AACA;IACI;QACI,4BAA4B;IAChC;AACJ;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,mBAAmB;IACnB,2BAA2B;AAC/B;;;AAGA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,kCAA0B;YAA1B,0BAA0B;IAC1B,cAAc;IACd,mBAAmB;IACnB,mBAAmB;IACnB,4FAA4F;IAC5F,iCAAiC;IACjC,4BAA4B;IAC5B,sBAAsB;AAC1B;;;AAGA;;;;;;;;;;;;;;;EAeE","sourcesContent":["\n@media (min-width: 0px) {\n    .guessLotteryContainer .MuiGrid-item {\n        padding-left: 8px !important;\n    }\n}\n\n.guessLotteryContainer>h6{\n    float: right;\n}\n\n.guessLotteryContainer{\n    width: 92vw;\n    height:auto;\n    margin: 8px auto;\n    border-radius: 16px;\n    border: 1.5px solid #FFA3F6;\n}\n\n\n.guessLotteryContainer .content{\n    position: relative;\n    overflow-x: hidden;\n    text-align: right;\n    width: auto;\n    height: 66px;\n    backdrop-filter: blur(8px);\n    display: block;\n    border-radius: 16px;\n    border: 0.5px solid;\n    background: linear-gradient(90deg, rgba(229, 66, 255, 0.4) 0%, rgba(137, 40, 153, 0.4) 100%);\n    /* background-position: center; */\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n\n\n/* .guessLotteryContainer .content{\n    position: inherit;\n    text-align: right;\n    width:100%;\n    height: 100%;\n    margin-top:0px;\n    margin-left:0px;\n    backdrop-filter: blur(8px);\n    display: block;\n    border-radius: 32px;\n    background-color:rgba(0, 0, 0, 0.4);\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
