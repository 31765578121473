import React from "react";
import { Box, Paper, Card, Grid } from "@mui/material";

import YButton from "../../Button/YButton";
import { toPersianDate } from "../../../utility/dateUtils";
import YTypoGraphy from "../../Text/YTypography/YTypoGraphy";
import { t } from "i18next";

interface WalletTransitionItemProps {
  transID: number;
  date: string;
  amount: number;
  type: 'CHARGE_WALLET_TETHER' | 'CHARGE_WALLET_TOMAN' | 'BUY_TICKET_TETHER' | 'BUY_TICKET_WALLET' | 'BUY_TICKET_TOMAN' | 'WITHDRAW_WALLET' | 'DEPOSIT_WALLET_FOR_WIN';
  gateway: string;
  pay_address: string;
  transaction_failed_reason: 'USER_TIME_OUT' | 'USER_OUT_OF_GATEWAY';
  transaction_status: 'PAYMENT_SUCCESS' | 'PAYMENT_WAITING' | 'PAYMENT_FAILED';
  invoice_url: string;
}

const WalletTransitionItem: React.FC<WalletTransitionItemProps> = ({
  transID,
  date,
  amount,
  type,
  gateway,
  pay_address,
  transaction_failed_reason,
  transaction_status,
  invoice_url,
}) => {
  return (
    <Paper
      elevation={2}
      sx={{ padding: 0, borderRadius: 4, maxWidth: "95%", margin: "16px auto" }}
    >
      <Box
        display={"flex"}
        sx={{
          backgroundColor: `${
            transaction_status === "PAYMENT_SUCCESS"
              ? "rgba(0, 158, 16, 1)"
              : transaction_status === "PAYMENT_FAILED"
              ? "rgba(209, 0, 0, 1)"
              : transaction_status === "PAYMENT_WAITING"
              ? "rgba(255, 153, 0, 1)"
              : ""
          }`,
          color: "white",
          padding: 0.5,
          textAlign: "center",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}
      >
        <YTypoGraphy variant="h6" fontSize={"0.7rem"} mr={1} mt={1}>
          {transaction_status === "PAYMENT_SUCCESS"
            ? t('wallet.transaction.success')
            : transaction_status === "PAYMENT_FAILED"
            ? t('wallet.transaction.failed')
            : transaction_status === "PAYMENT_WAITING"
            ? t('wallet.transaction.waiting')
            : ""}
        </YTypoGraphy>
        <YTypoGraphy
          variant="subtitle1"
          textAlign={"left"}
          sx={{ flexGrow: 1, marginLeft: "8px" }}
        >
          {toPersianDate(new Date(date))}
        </YTypoGraphy>
      </Box>

      <Box sx={{ padding: 1 }}>
        <Grid container p={1}>
          <Grid item xs={3.5}>
            <YTypoGraphy variant="body1" fontWeight={400}>
              {t('wallet.transaction.transaction_id')}
            </YTypoGraphy>
          </Grid>
          <Grid item xs={2}>
            <YTypoGraphy variant="body1" fontWeight={700} fontSize={'1rem'} textAlign={"left"}>
              #{transID}
            </YTypoGraphy>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            <YTypoGraphy variant="body1" fontWeight={400}>
              {t('wallet.transaction.value')}:
            </YTypoGraphy>
          </Grid>
          <Grid item xs={3.5}>
            <YTypoGraphy variant="body1" style={{ direction: 'ltr' }} fontWeight={700} fontSize={'1rem'} textAlign={"left"}>
              {amount.toFixed(3)} USDT
            </YTypoGraphy>
          </Grid>
          <Grid item xs={12}>
            <YTypoGraphy variant="body1" fontWeight={700} fontSize={'1rem'} textAlign={"left"} sx={{ direction: 'ltr' }}>
              TXID:
            </YTypoGraphy>
            <YTypoGraphy variant="body1" fontWeight={400} fontSize={'1rem'} textAlign={"left"} sx={{ direction: 'ltr', overflowWrap: 'break-word' }}>
              <a target="_blank" rel="noreferrer" href={`https://tronscan.org/#/transaction/${pay_address}`}>{pay_address}</a>
            </YTypoGraphy>

            {transaction_status === "PAYMENT_WAITING" && invoice_url !== '' && invoice_url !== null &&
              <a target="_blank" rel="noreferrer" href={invoice_url} style={{ textDecoration: 'none' }}>
                <YButton title={t('wallet.transaction.pay_invoice')} color="secondary-outline" width={180} height={40} mb={1} />
              </a>
            }
            {type === "DEPOSIT_WALLET_FOR_WIN" &&
              <YTypoGraphy variant="body1" fontWeight={400} textAlign={'center'} color={'green'}>
                {t('wallet.transaction.deposit_for_win')}
              </YTypoGraphy>
            }
            {type === "BUY_TICKET_TETHER" &&
              <YTypoGraphy variant="body1" fontWeight={400} textAlign={'center'} color={'blue'}>
                {t('wallet.transaction.buy_ticket_tether')}
              </YTypoGraphy>
            }
            {type === "BUY_TICKET_WALLET" &&
              <YTypoGraphy variant="body1" fontWeight={400} textAlign={'center'} color={'blue'}>
                {t('wallet.transaction.buy_ticket_wallet')}
              </YTypoGraphy>
            }
            {type === "WITHDRAW_WALLET" &&
              <YTypoGraphy variant="body1" fontWeight={400} textAlign={'center'} color={'red'}>
                {t('wallet.transaction.withdraw_wallet')}
              </YTypoGraphy>
            }
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default WalletTransitionItem;
