import  Button from "@mui/material/Button";
import React, { ReactNode } from "react";
import "./YButton.css";
import YTypoGraphy from "../Text/YTypography/YTypoGraphy";
import { SxProps } from "@mui/material/styles";
import { t } from "i18next";


interface YButtonProps {
  title: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  align?: "left" | "center" | "right" | null;
  float?: "inline-end" | "inline-start" | "left" | "none" | "right";
  color?:
    | "primary"
    | "primary-gray"
    | "primary-gray2"
    | "primary-outline"
    | "secondary-outline-not-selected"
    | "primary-outline-bottom"
    | "secondary"
    | "secondary-outline"
    | "edit"
    | "delete"
    | "add"
    | "deposit-withdraw"
    | "choose-random"
    | "add-ticket"
    | "increase-guess"
    | null;
  border?: string | "";
  radius?: "radius-primary" | "radius-secondary" | "radius-circle" | null;
  width?: number | string; // width prop
  height?: number | string; // height prop
  mb?: number | string; // margin bottom prop
  mt?: number | string; // margin top prop
  mr?: number | string; // margin right prop
  ml?: number | string; // margin left prop
  icon?: ReactNode; // icon prop
  iconPosition?: "left" | "right"; // icon position prop
  disabled?: boolean; // disabled prop
  marginAuto?: boolean; // disabled prop
  sx?: SxProps; // sx prop for custom styles
}

const YButton: React.FC<YButtonProps> = ({
  title,
  radius,
  border,
  color,
  align,
  float,
  onClick,
  width,
  height,
  mb,
  mt,
  mr,
  ml,
  icon,
  iconPosition = "left",
  disabled,
  marginAuto = true,
  sx,
}) => {
  const buttonStyle: React.CSSProperties = {
    border: border,
    width: width || "auto",
    height: height || "auto",
    marginBottom: typeof mb === "number" ? `${mb}rem` : mb,
    marginTop: typeof mt === "number" ? `${mt}rem` : mt,
    marginRight: typeof mr === "number" ? `${mr}rem` : mr,
    marginLeft: typeof ml === "number" ? `${ml}rem` : ml,
    position: iconPosition ? "relative" : "inherit",
    fontSize: "1.1rem",
    float:float,
    display:'block',
    margin:marginAuto?`0 auto`:''
  };

  return (
    <Button
      onClick={onClick}
      className={`y-btn ${radius || "radius-primary"} ${
        color || "primary-outline"
      } ${align || ""}`}
      style={buttonStyle}
      disabled={disabled}
      // sx={sx} // Applying the sx prop
    >
      {icon && iconPosition === "left" && (
        <span className="icon-left">{icon}</span>
      )}
      <span>{title}</span>

      {color=='increase-guess'&&(
        <YTypoGraphy mt={-0.5}>{t('result.increase_your_chance_by_buying_more_tickets')}</YTypoGraphy>
      )}
      {icon && iconPosition === "right" && (
        <span className="icon-right">{icon}</span>
      )}
    </Button>
  );
};

export default YButton;
