import { Grid }  from "@mui/material";
import { Image } from "@mui/icons-material";
import CounterPrizeComponent from "../../components/Numbers/Counter/CounterPrizeComponent";
import GuessPrizeCard from "../../components/Numbers/Guess/GuessPrizeCard/GuessPrizeCard";
import YButton from "../../components/Button/YButton";
import BottomArrowSvg from "../../components/SVG/BottomArrowSvg/BottomArrowSvg";
import GreenLightText from "../../components/Text/GreenLightText/GreenLightText";
import { useNavigate } from "react-router-dom";
import links from './links.json';


const Access = () => {

    const numberOfCounter = 6;
    const navigate  = useNavigate();

    function handleGoTo(path:string){
        navigate(`/fa${path}`);
    }

    return <>

  <Grid container spacing={2} mt={3} mb={4} pr={6} pl={6}>
     
  </Grid>

  {links.map((item: any, i: number) => (
    
        <YButton
          key={i} // Ensure to provide a unique key prop for each element in array
          title={item.title}
          color={'secondary-outline'}
          onClick={() => handleGoTo(item.link)}
          align={'center'}
          radius={'radius-primary'}
          width={250}
          height={40}
          mb={0.5}
        />
      ))}
    
        <BottomArrowSvg
          float={'right'}
          dir='rtl'
        />

      </>;
  };
  
export default Access;
