import { Grid } from "@mui/material";
import "./TermsOfService.css";
import { Image } from "@mui/icons-material";
import YButton from "../../../components/Button/YButton";
import BottomArrowSvg from "../../../components/SVG/BottomArrowSvg/BottomArrowSvg";
import { useNavigate } from "react-router-dom";
import YTypoGraphy from "../../../components/Text/YTypography/YTypoGraphy";
import { t } from "i18next";

const TermsOfService = () => {
  return (
    <>
      <Grid container mt={4} p={3}>
        <YTypoGraphy
          variant="h3"
          textAlign={"center"}
          fontSize={"1.3rem"}
          margin={"0 auto"}
          color={"white"}
        >
          {t("drawer.terms_and_conditions")}
        </YTypoGraphy>
        <YTypoGraphy
          variant="body1"
          mt={3}
          lineHeight={"1.8rem"}
          textAlign={"justify"}
          color={"white"}
        >
          {t("lorem")}
        </YTypoGraphy>
      </Grid>
    </>
  );
};

export default TermsOfService;
