import React from 'react';
import { ListItem,  ListItemText, Avatar } from '@mui/material';
import { LotteryResultsResponse, WinsMember } from '../../../services/resultsService';
import YTypoGraphy from '../../Text/YTypography/YTypoGraphy';


interface WinnerListItemProps {
    index: number;
    winner: WinsMember;
  }
  
  const WinnerListItem: React.FC<WinnerListItemProps> = ({
    index,
    winner,
  }) => {

  return (
    <ListItem key={index}>
      <YTypoGraphy
        variant="h6"
        textAlign="left"
        color="white"
        sx={{ direction: "rtl", display: "flex" }}
      >
        {`${winner.win_amount.toFixed(2)} `}
        <YTypoGraphy variant="body1" pr={0.5} color="white">
          تتر
        </YTypoGraphy>
      </YTypoGraphy>
      <ListItemText
        primary={winner.username}
        secondary={winner.gender === 'MALE' ? 'آقا' : winner.gender === 'FEMALE' ? 'خانم' : '-'}
        sx={{
          paddingRight: 2,
          textAlign: "right",
          "& .MuiListItemText-primary": { color: "white" },
          "& .MuiListItemText-secondary": { color: "white" },
        }}
      />
      <Avatar />
    </ListItem>
  );
};

export default WinnerListItem;
