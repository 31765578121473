import React, { useState } from "react";
import { Modal, Slide, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import YButton from "../../../../components/Button/YButton";
import YTextField from "../../../../components/TextField/YTextField";
import { ProfileData } from "../../../../interfaces/auth/profileData";
import { login } from "../../../../services/authService";
import NotifyAlert from "../../../../components/NotifyAlert/NotifyAlert";

interface AuthBottomSheetProps {
  handleUpdate: (message: string, isNewUser: string) => void;
}

const AuthBottomSheetStep1: React.FC<AuthBottomSheetProps> = ({
  handleUpdate,
}) => {
  const [formState, setFormState] = useState({
    username: "",
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      const { message, isNewUser } = await login(formState.username);
      console.log("login successful:", message);
      localStorage.setItem("username", formState.username);
      handleUpdate(message, isNewUser);
    } catch (error) {
      setError("Verificlogination failed. Please check your phone number.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <YTextField
        label="ایمیل/شماره موبایل خود را وارد"
        value={formState.username}
        setValue={(value: string) =>
          setFormState((prevState) => ({ ...prevState, username: value }))
        }
        type="text"
      />

      <YButton
        title="ذخیره"
        color="secondary-outline"
        width={'85vw'}
        height={50}
        onClick={handleSubmit}
      />
    </>
  );
};

export default AuthBottomSheetStep1;
