import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next"; // Import useTranslation
import YButton from "../../../components/Button/YButton";
import YTextField from "../../../components/TextField/YTextField";
import { UserProfileResponse } from "../../../services/authService";
import {
  getCountries,
  RefCountriesResponse,
} from "../../../services/refService";

interface ProfileBottomSheetProps {
  handleUpdate: (data: UserProfileResponse | null) => void;
  data: UserProfileResponse | null;
}

const ProfileBottomSheet: React.FC<ProfileBottomSheetProps> = ({
  handleUpdate,
  data,
}) => {
  const { t } = useTranslation(); // Use useTranslation hook
  const [profile, setProfile] = useState<UserProfileResponse | null>(data);
  const [countries, setCountries] = useState<RefCountriesResponse | null>(null);

  const fetchCountries = async () => {
    try {
      const countryData = await getCountries();
      setCountries(countryData);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const handleChange =
    (
      field:
        | keyof UserProfileResponse["user"]
        | keyof UserProfileResponse["country_name"]
    ) =>
    (value: string) => {
      if (profile) {
        setProfile({
          ...profile,
          user: {
            ...profile.user,
            [field]: value,
          },
          country_name: {
            ...profile.country_name,
            [field]: value,
          },
        });
      }
    };

  const Update = () => {
    handleUpdate(profile);
  };

  if (!profile || !countries) {
    return <div>{t("loading")}</div>; // Use t() for loading text
  }

  return (
    <>
      <YTextField
        label={t("profile.photo")} // Translate key
        value={profile.user.username || ""}
        setValue={handleChange("username")}
        type="text"
        uitype="purple"
      />

      <YTextField
        label={t("profile.firstName")} // Translate key
        value={profile.user.firstname || ""}
        setValue={handleChange("firstname")}
        type="text"
        uitype="purple"
      />

      <YTextField
        label={t("profile.lastName")} // Translate key
        value={profile.user.lastname || ""}
        setValue={handleChange("lastname")}
        type="text"
        uitype="purple"
      />
      
      <YTextField
        label={t("profile.gender")} // Translate key
        type="select"
        fullWidth
        select
        value={profile.user.gender || ""}
        setValue={handleChange("gender")}
        options={[
          { value: "MALE", label: t("profile.male") }, // Translate key
          { value: "FEMALE", label: t("profile.female") }, // Translate key
          // { value: "NOT_SET", label: t("profile.notSet") }, // Translate key
        ]}
        uitype="purple"
      />

      <YTextField
        label={t("profile.phoneNumber")} // Translate key
        value={profile.user.username || ""}
        setValue={handleChange("username")}
        type="tel"
        uitype="purple"
      />

      <YTextField
        label={t("profile.email")} // Translate key
        value={profile.user.email || ""}
        setValue={handleChange("email")}
        type="email"
        uitype="purple"
      />

      <YTextField
        label={t("profile.country")} // Translate key
        type="select"
        select
        fullWidth
        setDefaultID={profile.user.country_id}
        options={countries.data}
        value={profile.country_name?.fa_name || ""}
        setValue={handleChange("fa_name")}
        uitype="purple"
      />

      <YButton
        title={t("profile.save")} // Translate key
        color="secondary-outline"
        width={"85vw"}
        height={50}
        onClick={Update}
      />
    </>
  );
};

export default ProfileBottomSheet;
