import { Box, Grid } from "@mui/material";
import "./home.css";
import { Image } from "@mui/icons-material";
import CounterPrizeComponent from "../../components/Numbers/Counter/CounterPrizeComponent";
import GuessPrizeCard from "../../components/Numbers/Guess/GuessPrizeCard/GuessPrizeCard";
import YButton from "../../components/Button/YButton";
import BottomArrowSvg from "../../components/SVG/BottomArrowSvg/BottomArrowSvg";
import GreenLightText from "../../components/Text/GreenLightText/GreenLightText";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getActiveLottery } from "../../services/lotteryService";
import ClipLoader from "react-spinners/ClipLoader";
import { loginTelegram } from "../../services/authService";
import YTypoGraphy from "../../components/Text/YTypography/YTypoGraphy";
import BannerSection from "../../components/Banners/BannerSection";
import { IS_ON_SERVER } from "../../utility/apiConfig";
import { t } from "i18next";

const Home = () => {
  const numberOfCounter = 6;
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [prizes, setPrizes] = useState<number[]>([]);
  const [prizeBalance, setPrizeBalance] = useState<number>(0);

  const [lotteryAllAmount, setLotteryAllAmount] = useState<number>(0);
  const [timer, setTimer] = useState<Date | undefined>();

  // const [remainingTime, setRemainingTime] = useState<string>("");

  const [remainingTimeDay, setRemainingTimeDay] = useState<string>("");
  const [remainingTimeHour, setRemainingTimeHour] = useState<string>("");
  const [remainingTimeMin, setRemainingTimeMin] = useState<string>("");
  const [remainingTimeSecond, setRemainingTimeSecond] = useState<string>("");

  async function handleActiveLottery() {
    const active = await getActiveLottery();
    if (active?.activeLottery) {
      setLotteryAllAmount(active.totalPrice.amount);
      const lotteryDate = new Date(active.activeLottery.lottery_date);
      setTimer(lotteryDate);
    }
    // if (!active?.status) setRemainingTime("قرعه کشی فعلا برگزار نشده است...");

    setLoading(false);
    setPrizeBalance(active?.totalPrice?.amount ?? 0);
    setPrizes([
      active?.prizeAmounts?.sixthPrizeAmount ?? 0,
      active?.prizeAmounts?.fifthPrizeAmount ?? 0,
      active?.prizeAmounts?.forthPrizeAmount ?? 0,
      active?.prizeAmounts?.thirdPrizeAmount ?? 0,
      active?.prizeAmounts?.secondPrizeAmount ?? 0,
    ]);
  }

  async function handleTelegramLogin() {
    let login;

    if (IS_ON_SERVER) {
      login = await loginTelegram(
        window.chat_id,
        window.first_name,
        window.last_name
      );
    } else {
      login = await loginTelegram("7428308651", "ma", "fa");
    }

    if (login != null && login.status) {
      localStorage.setItem("token", login.token);
      //  window.location.reload();
    }
  }

  useEffect(() => {
    if (!timer) return;

    const interval = setInterval(() => {
      const now = new Date();
      const timeDiff = timer.getTime() - now.getTime();

      if (timeDiff <= 0) {
        clearInterval(interval);
        // setRemainingTime("آماده سازی برای شروع قرعه کشی...");
        navigate("/fa/lottery");
        return;
      }

      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      let timeString = "";
      if (days > 0) timeString += `${days} روز `;
      if (hours > 0 || days > 0) timeString += `${hours} ساعت `;
      if (minutes > 0 || hours > 0 || days > 0)
        timeString += `${minutes} دقیقه `;
      if (seconds > 0 || minutes > 0 || hours > 0 || days > 0)
        timeString += `${seconds} ثانیه `;

      setRemainingTimeDay(days.toString());
      setRemainingTimeHour(hours.toString());
      setRemainingTimeMin(minutes.toString());
      setRemainingTimeSecond(seconds.toString());
      // setRemainingTime(timeString);
    }, 1000); // Update interval set to 1000 ms (1 second)

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    handleActiveLottery();

    handleTelegramLogin();
  }, []);

  function handleGoTo() {
    navigate("/fa/buy");
  }

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {loading ? (
            <ClipLoader color="white" size={100} />
          ) : (
            <h1>Content Loaded</h1>
          )}
        </div>
      ) : (
        <>
        
          <Grid
            container
            spacing={{ xs: 1 }}
            columnGap={0}
            rowGap={1}
            mr={"2.5vw"}
            width={"95vw !important"}
            border={"#FFA3F68A solid"}
            borderRadius={4}
            direction="row-reverse"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <GreenLightText title={t('lottery.prize_number')} />
            </Grid>

            <div
              style={{
                backgroundColor: "#D5ACFF",
                height: "1px",
                width: "100vw",
              }}
            ></div>

            <Grid item key={0} mb={2}>
              <CounterPrizeComponent
                targetNumber={"$"}
                isMainPage={true}
                fontSize={38}
                hasAnimation={false}
              />
            </Grid>

            {prizeBalance
              // {prizeBalance
              .toFixed(0)
              .toString()
              .split("")
              .map((item, index) => (
                <Grid item key={index + 1} mb={2}>
                  <CounterPrizeComponent
                    targetNumber={parseFloat(item)}
                    isMainPage={true}
                    fontSize={40}
                    hasAnimation={false}
                  />
                </Grid>
              ))}
          </Grid>

          <Grid
            container
            mt={2}
            mr={"2.5vw"}
            height={"79px"}
            width={"95vw !important"}
            border={"#FFA3F68A solid"}
            borderRadius={4}
            rowSpacing={1}
          >
            <Grid
              item
              xs={3}
              sx={{
                position: "relative",
                borderLeft: "#FFA3F68A 1px solid",
                height: "79px",
              }}
            >
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  paddingTop: "8px",
                  position: "absolute",
                  top: "5px",
                  right: "16px",
                }}
              >
                <path
                  d="M15.967 7.30197C17.1561 7.76593 17.11 9.31594 16.1627 10.2633L10.8572 15.5687C9.90991 16.5161 8.3599 16.5621 7.89594 15.3731C7.61502 14.6531 7.48205 13.8766 7.50464 13.088C7.52723 12.2994 7.70493 11.514 8.02759 10.7768C8.35026 10.0395 8.81157 9.36483 9.38519 8.79121C9.95881 8.21759 10.6335 7.75628 11.3707 7.43362C12.108 7.11095 12.8933 6.93325 13.682 6.91067C14.4706 6.88808 15.2471 7.02104 15.967 7.30197Z"
                  fill="white"
                />
                <path
                  d="M29.75 18.6664C29.75 17.6999 28.9665 16.9164 28 16.9164C27.0335 16.9164 26.25 17.6999 26.25 18.6664V32.6664C26.25 33.6329 27.0335 34.4164 28 34.4164H37.3333C38.2998 34.4164 39.0833 33.6329 39.0833 32.6664C39.0833 31.6999 38.2998 30.9164 37.3333 30.9164H29.75V18.6664Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M49 30.3331C49 41.9311 39.598 51.3331 28 51.3331C16.402 51.3331 7 41.9311 7 30.3331C7 18.7351 16.402 9.3331 28 9.3331C39.598 9.3331 49 18.7351 49 30.3331ZM45.5 30.3331C45.5 39.9981 37.665 47.8331 28 47.8331C18.335 47.8331 10.5 39.9981 10.5 30.3331C10.5 20.6681 18.335 12.8331 28 12.8331C37.665 12.8331 45.5 20.6681 45.5 30.3331Z"
                  fill="white"
                />
                <path
                  d="M39.8477 10.2633C38.9004 9.31594 38.8543 7.76593 40.0434 7.30197C40.7633 7.02104 41.5398 6.88808 42.3284 6.91067C43.117 6.93325 43.9024 7.11095 44.6397 7.43362C45.3769 7.75628 46.0516 8.21759 46.6252 8.79121C47.1988 9.36483 47.6601 10.0395 47.9828 10.7768C48.3055 11.514 48.4832 12.2994 48.5058 13.088C48.5283 13.8766 48.3954 14.6531 48.1145 15.3731C47.6505 16.5621 46.1005 16.5161 45.1531 15.5687L39.8477 10.2633Z"
                  fill="white"
                />
              </svg>
            </Grid>
            <Grid item xs={3}>
              <YTypoGraphy
                variant="h5"
                color={"white"}
                fontSize={16}
                textAlign={"center"}
                fontWeight={500}
                width={"100%"}
              >
                {remainingTimeDay}
                <br /> <span> {t('lottery.day')}</span>
              </YTypoGraphy>
            </Grid>
            <Grid item xs={3}>
              <YTypoGraphy
                variant="h5"
                color={"white"}
                fontSize={16}
                textAlign={"center"}
                fontWeight={500}
                width={"100%"}
              >
                {remainingTimeHour}
                <br /> <span> {t('lottery.hour')}</span>
              </YTypoGraphy>
            </Grid>
            <Grid item xs={3}>
              <YTypoGraphy
                variant="h5"
                color={"white"}
                fontSize={16}
                textAlign={"center"}
                fontWeight={500}
                width={"100%"}
              >
                {remainingTimeMin}
                <br /> <span> {t('lottery.miniute')}</span>
              </YTypoGraphy>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>

          {/* <Grid container spacing={2} mt={3} mb={1} pr={6} pl={6}></Grid> */}

          {/* #FFA3F68A */}

          <GuessPrizeCard guesses={[6, 5, 4, 3, 2]} prizes={prizes} />

     
          <BannerSection mb={3} />
          


          <BottomArrowSvg float={"cehte"} dir="ltr" />

          <YButton
            title={t("lets_go")}
            color={"secondary-outline"}
            onClick={handleGoTo}
            align={"center"}
            radius={"radius-primary"}
            width={"43vw"}
            height={58}
            mb={2}
            mt={2}
          />

          <BottomArrowSvg float={"right"} dir="rtl" />

          <Grid mt={4}></Grid>
        </>
      )}
    </>
  );
};

export default Home;
