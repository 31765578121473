import { Button } from "@mui/base";
import React from "react";
import "./BalanceCardSvg.css";

interface BalanceCardSvgProps {
  dir: string;
  float: string;
  color?: string;
}
// { title, align, onClick }
// BalanceCardSvgProps
const BalanceCardSvg: React.FC<BalanceCardSvgProps> = ({
  dir,
  float,
  color,
}) => {
  return (
    // <div dir={dir} className={` ${float}`}>
    <>
      {/* <img
        width={30}
        src="/images/icons/USDT.png"
        style={{
          position: "absolute",
          top: 46,
          left: 30,
        }}
      /> */}
      <svg
        width="396"
        height="156"
        viewBox="0 0 396 156"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ margin: "16px auto" }}
      >
        <rect width="396" height="156" rx="10" fill="#060606" />

        <g opacity="0.1">
          <mask
            id="mask0_7_75"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="396"
            height="156"
          >
            <rect width="396" height="156" rx="10" fill="#2F2F2F" />
          </mask>
          <g mask="url(#mask0_7_75)">
            <path
              d="M339.124 81.7449C341.266 78.0918 345.213 75.8474 349.496 75.8474L417.616 75.8474C421.942 75.8474 425.952 78.1364 428.128 81.8471L462.107 139.81C464.283 143.521 464.29 148.086 462.128 151.775L428.068 209.875C425.926 213.528 421.979 215.772 417.696 215.772L349.576 215.772C345.25 215.772 341.24 213.483 339.065 209.773L305.085 151.81C302.909 148.099 302.902 143.534 305.065 139.845L339.124 81.7449Z"
              fill="#B353FF"
            />
          </g>
        </g>
        <g opacity="0.1">
          <mask
            id="mask1_7_75"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="396"
            height="156"
          >
            <rect width="396" height="156" rx="10" fill="#2F2F2F" />
          </mask>
          <g mask="url(#mask1_7_75)">
            <path
              d="M34.974 -32.8141C38.9582 -35.0796 43.8411 -35.0796 47.8254 -32.8141L105.864 0.186935C109.928 2.49775 112.438 6.81277 112.438 11.4878V77.1481C112.438 81.8231 109.928 86.1381 105.864 88.449L47.8254 121.45C43.8411 123.715 38.9582 123.715 34.9739 121.45L-23.0649 88.4489C-27.1289 86.1381 -29.6392 81.8231 -29.6392 77.1481V11.4878C-29.6392 6.81278 -27.1289 2.49774 -23.0649 0.186929L34.974 -32.8141Z"
              fill="#FFC700"
            />
          </g>
        </g>
        <path
          d="M34.974 -32.8141C38.9582 -35.0796 43.8411 -35.0796 47.8254 -32.8141L105.864 0.186935C109.928 2.49775 112.438 6.81277 112.438 11.4878V77.1481C112.438 81.8231 109.928 86.1381 105.864 88.449L47.8254 121.45C43.8411 123.715 38.9582 123.715 34.9739 121.45L-23.0649 88.4489C-27.1289 86.1381 -29.6392 81.8231 -29.6392 77.1481V11.4878C-29.6392 6.81278 -27.1289 2.49774 -23.0649 0.186929L34.974 -32.8141Z"
          fill="#1f1905"
        />

        <path
          d="M339.124 81.7449C341.266 78.0918 345.213 75.8474 349.496 75.8474L417.616 75.8474C421.942 75.8474 425.952 78.1364 428.128 81.8471L462.107 139.81C464.283 143.521 464.29 148.086 462.128 151.775L428.068 209.875C425.926 213.528 421.979 215.772 417.696 215.772L349.576 215.772C345.25 215.772 341.24 213.483 339.065 209.773L305.085 151.81C302.909 148.099 302.902 143.534 305.065 139.845L339.124 81.7449Z"
          fill="#170e1f"
        />

        <svg width="100" height="100">
          <path
            d="M41.75 18.4537C43.7611 17.2926 46.2389 17.2926 48.25 18.4537L66.3647 28.9123C68.3758 30.0734 69.6147 32.2192 69.6147 34.5415V55.4585C69.6147 57.7808 68.3758 59.9266 66.3647 61.0877L48.25 71.5463C46.2389 72.7074 43.7611 72.7074 41.75 71.5463L23.6353 61.0877C21.6242 59.9266 20.3853 57.7808 20.3853 55.4585V34.5415C20.3853 32.2192 21.6242 30.0734 23.6353 28.9123L41.75 18.4537Z"
            fill="#2F2F2F"
            stroke="#26A17B"
          />
          <image href="/images/icons/USDT.png" width="30" x="30" y="30" />
        </svg>

        <rect x="30" y="30" width="30" height="30" fill="url(#pattern0_7_75)" />
        <defs>
          <pattern
            id="pattern0_7_75"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use transform="scale(0.00195312)" />
          </pattern>
        </defs>
      </svg>
    </>
    // </div>
  );
};

export default BalanceCardSvg;
