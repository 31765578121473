import React, { useState, useEffect } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";

const BottomNav: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState("");
  const [position, setPosition] = useState(1);
  const selectedLanguage = localStorage.getItem("selectedLanguage");

  // Define the paths based on the selected language
  const paths = {
    home: selectedLanguage === "fa" ? "/fa" : "/",
    buy: selectedLanguage === "fa" ? "/fa/buy/choosed-number" : "/buy/choosed-number",
    results: selectedLanguage === "fa" ? "/fa/lottery/my-results" : "/lottery/my-results",
    wallet: selectedLanguage === "fa" ? "/fa/payment/wallet" : "/payment/wallet",
  };

  // useEffect to check location changes
  useEffect(() => {
    console.log("Location changed:", location.pathname);
    // Update position based on the current location
    if (location.pathname === paths.home) setPosition(1);
    else if (location.pathname === paths.buy) setPosition(2);
    else if (location.pathname === paths.results) setPosition(3);
    else if (location.pathname === paths.wallet) setPosition(4);

    setValue(location.pathname);
  }, [location, paths]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    navigate(newValue);
  };

  return (
    <div style={{ marginTop: "7rem" }}>
      <BottomNavigation
        showLabels
        sx={{
          position: "fixed",
          height: "75px",
          bottom: 0,
          left: 0,
          right: 0,
          paddingRight: "8px",
          paddingLeft: "8px",
          zIndex: 1000,
          backgroundColor: "#BA22A0",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          "& .MuiBottomNavigationAction-root": {
            color: "white",
            fontFamily: "IRANYekan, sans-serif",
          },
          direction:
            localStorage.getItem("selectedLanguage") === "fa" ? "rtl" : "ltr",
        }}
        value={value}
        onChange={handleChange}
      >
        {/* 4 */}
        {/* 28 */}
        {/* 51 */}
        {/* 74 */}

        <div
          style={{
            position: "absolute",
            [localStorage.getItem("selectedLanguage") === "fa" ? 'left' : 'right']: `${4 + (4 - position) * 23}vw`, // Set left for fa, right for en
            top: "-7px",
          }}
        >
          <svg
            width="89"
            height="6"
            viewBox="0 0 89 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0H89C89 3.31371 86.3137 6 83 6H6C2.68629 6 0 3.31371 0 0Z"
              fill="white"
            />
          </svg>
        </div>

        <BottomNavigationAction
          label={t("home")}
          value={paths.home}
          icon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M20.0402 6.82018L14.2802 2.79018C12.7102 1.69018 10.3002 1.75018 8.79023 2.92018L3.78023 6.83018C2.78023 7.61018 1.99023 9.21018 1.99023 10.4702V17.3702C1.99023 19.9202 4.06023 22.0002 6.61023 22.0002H17.3902C19.9402 22.0002 22.0102 19.9302 22.0102 17.3802V10.6002C22.0102 9.25018 21.1402 7.59018 20.0402 6.82018Z"
                fill="white"
              />
              <path
                d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V15C11.25 14.59 11.59 14.25 12 14.25C12.41 14.25 12.75 14.59 12.75 15V18C12.75 18.41 12.41 18.75 12 18.75Z"
                fill="white"
              />
            </svg>
          }
          showLabel={value === "/fa" || value === "/fa/"}
        />

        <BottomNavigationAction
          label={t("buyticket")}
          value={paths.buy}
          icon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.6998 22.7502H9.29977C4.35977 22.7502 2.25977 20.6402 2.25977 15.7102V11.2202C2.25977 10.8102 2.59977 10.4702 3.00977 10.4702C3.41977 10.4702 3.75977 10.8102 3.75977 11.2202V15.7102C3.75977 19.8002 5.20977 21.2502 9.29977 21.2502H14.6898C18.7798 21.2502 20.2298 19.8002 20.2298 15.7102V11.2202C20.2298 10.8102 20.5698 10.4702 20.9798 10.4702C21.3898 10.4702 21.7298 10.8102 21.7298 11.2202V15.7102C21.7398 20.6402 19.6298 22.7502 14.6998 22.7502Z"
                fill="white"
              />
              <path
                d="M12 12.75C10.9 12.75 9.9 12.32 9.19 11.53C8.48 10.74 8.15 9.71 8.26 8.61L8.93 1.93C8.97 1.55 9.29 1.25 9.68 1.25H14.35C14.74 1.25 15.06 1.54 15.1 1.93L15.77 8.61C15.88 9.71 15.55 10.74 14.84 11.53C14.1 12.32 13.1 12.75 12 12.75ZM10.35 2.75L9.75 8.76C9.68 9.43 9.88 10.06 10.3 10.52C11.15 11.46 12.85 11.46 13.7 10.52C14.12 10.05 14.32 9.42 14.25 8.76L13.65 2.75H10.35Z"
                fill="white"
              />
              <path
                d="M18.31 12.75C16.28 12.75 14.47 11.11 14.26 9.09L13.56 2.08C13.54 1.87 13.61 1.66 13.75 1.5C13.89 1.34 14.09 1.25 14.31 1.25H17.36C20.3 1.25 21.67 2.48 22.08 5.5L22.36 8.28C22.48 9.46 22.12 10.58 21.35 11.43C20.58 12.28 19.5 12.75 18.31 12.75ZM15.14 2.75L15.76 8.94C15.89 10.19 17.05 11.25 18.31 11.25C19.07 11.25 19.75 10.96 20.24 10.43C20.72 9.9 20.94 9.19 20.87 8.43L20.59 5.68C20.28 3.42 19.55 2.75 17.36 2.75H15.14Z"
                fill="white"
              />
              <path
                d="M5.64002 12.75C4.45002 12.75 3.37002 12.28 2.60002 11.43C1.83002 10.58 1.47002 9.46 1.59002 8.28L1.86002 5.53C2.28002 2.48 3.65002 1.25 6.59002 1.25H9.64002C9.85002 1.25 10.05 1.34 10.2 1.5C10.35 1.66 10.41 1.87 10.39 2.08L9.69002 9.09C9.48002 11.11 7.67002 12.75 5.64002 12.75ZM6.59002 2.75C4.40002 2.75 3.67002 3.41 3.35002 5.7L3.08002 8.43C3.00002 9.19 3.23002 9.9 3.71002 10.43C4.19002 10.96 4.87002 11.25 5.64002 11.25C6.90002 11.25 8.07002 10.19 8.19002 8.94L8.81002 2.75H6.59002Z"
                fill="white"
              />
              <path
                d="M14.5 22.75H9.5C9.09 22.75 8.75 22.41 8.75 22V19.5C8.75 17.4 9.9 16.25 12 16.25C14.1 16.25 15.25 17.4 15.25 19.5V22C15.25 22.41 14.91 22.75 14.5 22.75ZM10.25 21.25H13.75V19.5C13.75 18.24 13.26 17.75 12 17.75C10.74 17.75 10.25 18.24 10.25 19.5V21.25Z"
                fill="white"
              />
            </svg>
          }
          showLabel={value.startsWith("/fa/buy")}
        />

        <BottomNavigationAction
          label={t("result.result")}
          value={paths.results}
          icon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
                fill="white"
              />
              <path
                d="M21.9999 22.7499C21.8099 22.7499 21.6199 22.6799 21.4699 22.5299L19.4699 20.5299C19.1799 20.2399 19.1799 19.7599 19.4699 19.4699C19.7599 19.1799 20.2399 19.1799 20.5299 19.4699L22.5299 21.4699C22.8199 21.7599 22.8199 22.2399 22.5299 22.5299C22.3799 22.6799 22.1899 22.7499 21.9999 22.7499Z"
                fill="white"
              />
            </svg>
          }
          showLabel={false}
        />

        <BottomNavigationAction
          label={t("wallet.wallet")}
          value={paths.wallet}
          icon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 6.31006H12C11.59 6.31006 11.25 5.97006 11.25 5.56006C11.25 5.15006 11.59 4.81006 12 4.81006H22C22.41 4.81006 22.75 5.15006 22.75 5.56006C22.75 5.98006 22.41 6.31006 22 6.31006Z"
                fill="white"
              />
              <path
                d="M19.7798 11.25H14.2298C12.0398 11.25 11.2598 10.48 11.2598 8.3V4.2C11.2598 2.02 12.0398 1.25 14.2298 1.25H19.7798C21.9698 1.25 22.7498 2.02 22.7498 4.2V8.31C22.7498 10.48 21.9698 11.25 19.7798 11.25ZM14.2198 2.75C12.8598 2.75 12.7498 2.86 12.7498 4.2V8.31C12.7498 9.65 12.8598 9.76 14.2198 9.76H19.7697C21.1297 9.76 21.2397 9.65 21.2397 8.31V4.2C21.2397 2.86 21.1297 2.75 19.7697 2.75H14.2198Z"
                fill="white"
              />
              <path
                d="M12 17.8101H2C1.59 17.8101 1.25 17.4701 1.25 17.0601C1.25 16.6501 1.59 16.3101 2 16.3101H12C12.41 16.3101 12.75 16.6501 12.75 17.0601C12.75 17.4801 12.41 17.8101 12 17.8101Z"
                fill="white"
              />
              <path
                d="M9.77975 22.75H4.22977C2.03977 22.75 1.25977 21.98 1.25977 19.8V15.7C1.25977 13.52 2.03977 12.75 4.22977 12.75H9.77975C11.9698 12.75 12.7498 13.52 12.7498 15.7V19.81C12.7498 21.98 11.9698 22.75 9.77975 22.75ZM4.21976 14.25C2.85976 14.25 2.74976 14.36 2.74976 15.7V19.81C2.74976 21.15 2.85976 21.26 4.21976 21.26H9.76974C11.1297 21.26 11.2397 21.15 11.2397 19.81V15.7C11.2397 14.36 11.1297 14.25 9.76974 14.25H4.21976Z"
                fill="white"
              />
              <path
                d="M15.0002 22.7502C14.7302 22.7502 14.4802 22.6002 14.3502 22.3702C14.2202 22.1302 14.2202 21.8502 14.3602 21.6102L15.4102 19.8602C15.6202 19.5102 16.0802 19.3902 16.4402 19.6002C16.8002 19.8102 16.9102 20.2702 16.7002 20.6302L16.4302 21.0802C19.1902 20.4302 21.2602 17.9502 21.2602 14.9902C21.2602 14.5802 21.6002 14.2402 22.0102 14.2402C22.4202 14.2402 22.7602 14.5802 22.7602 14.9902C22.7502 19.2702 19.2702 22.7502 15.0002 22.7502Z"
                fill="white"
              />
              <path
                d="M2 9.75C1.59 9.75 1.25 9.41 1.25 9C1.25 4.73 4.73 1.25 9 1.25C9.27 1.25 9.51999 1.4 9.64999 1.63C9.77999 1.87 9.78001 2.15 9.64001 2.39L8.59 4.14C8.38 4.49 7.92 4.61 7.56 4.4C7.2 4.19 7.08999 3.73 7.29999 3.37L7.57001 2.92C4.81001 3.57 2.73999 6.05 2.73999 9.01C2.74999 9.41 2.41 9.75 2 9.75Z"
                fill="white"
              />
            </svg>
          }
          showLabel={value.startsWith("/fa/payment/wallet")}
        />
      </BottomNavigation>
    </div>
  );
};

export default BottomNav;
