import { t } from "i18next";
import i18n from "../i18n/i18n";

// Check for language in localStorage
const selectedLanguage = localStorage.getItem("selectedLanguage") || "en";

// Apply the selected language to i18next
i18n.changeLanguage(selectedLanguage);


const drawerItems = {
  main: [
    {
      title: t("drawer.login_register"),
      link: "/auth/step1",
    },
    {
      title: t("drawer.home"),
      link: "/",
    },
    {
      title: t("drawer.menu"),
      link: "/menu",
    },
    {
      title: t("drawer.winners"),
      link: "/lottery/my-results",
    },
    {
      title: t("drawer.install_app"),
      link: "",
    },
    {
      title: t("drawer.support"),
      link: "",
    },
    {
      title: t("drawer.terms_of_service"),
      link: "/terms-of-service",
    },
    {
      title: t("drawer.get_agency"),
      link: "",
    },
  ],

  account: [
    {
      title: t("drawer.user_info"),
      link: "/profile",
    },
    {
      title: t("drawer.wallet"),
      link: "/payment/wallet",
    },
    {
      title: t("drawer.security"),
      link: "/security",
    },
    {
      title: t("drawer.logout"),
      link: "/logout",
    },
  ],
};

export default drawerItems;
