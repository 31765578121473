import { Grid} from '@mui/material';
import './GuessPrizeCard.css';
import CountUp from 'react-countup';
import PrizeCircle from '../PrizeCircle/PrizeCircle';
import PrizePersianCurrency from '../PrizePersianCurrency/PrizePersianCurrency';
import YTypoGraphy from '../../../Text/YTypography/YTypoGraphy';
import { t } from 'i18next';


interface GuessPrizeCardProps {
    guesses: number[];
    prizes: number[];
}

const GuessPrizeCard = ({ guesses, prizes }: GuessPrizeCardProps): JSX.Element => {
    return (
        <section className='guessPrizeContainer'>
            <div className='content'>
                <Grid container spacing={{ xs: 6 }} pt={2} pb={2} pr={3} pl={3}>
                    <Grid item xs={6}>
                        <YTypoGraphy variant="h6" color={'white'} textAlign={'right'} pr={2}>{t('lottery.guess')}</YTypoGraphy>
                        {guesses.map((count, index) => (
                            <PrizeCircle key={index} count={count} mt={index === 0 ? 2 : 3} />
                        ))}
                    </Grid>
                    <Grid item xs={6}>
                        <YTypoGraphy variant="h6" color={'white'} textAlign={'center'}>{t('lottery.prize')}</YTypoGraphy>
                        {prizes.map((amount, index) => (
                            <PrizePersianCurrency key={index} align='center' amount={amount} mt={index === 0 ? 3 : 4} />
                        ))}
                    </Grid>
                </Grid>
            </div>
        </section>
    );
};

export default GuessPrizeCard;