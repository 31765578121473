import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import Layout from "./Layouts/layout";
import Home from "./pages/home/page";
import Lottery from "./pages/Lottery/page";
import DisableZoom from "./DisableZoom";
import BuyNumber from "./pages/Payment/BuyNumber/page";
import Access from "./pages/Access/page";
import Profile from "./pages/Auth/Profile/page";
import Security from "./pages/Auth/Security/page";
import MyResults from "./pages/Lottery/MyResults/page";
import ChooseNumber from "./pages/Payment/ChooseNumber/page";
import Wallet from "./pages/Payment/Wallet/page";
import TermsOfService from "./pages/Others/TermsOfService/page";
import ReceiptionSuccess from "./pages/Payment/Receiption/Success/page";
import ReceiptionFailed from "./pages/Payment/Receiption/Failed/page";
import WhiteLayout from "./Layouts/WhiteLayout";
import AuthenticationStep1 from "./pages/Auth/Authentication/step1/page";
import AuthenticationStep2 from "./pages/Auth/Authentication/step2/page";
import AuthenticationForget from "./pages/Auth/Authentication/setpassword/page";
import ChoosedNumber from "./pages/Payment/ChoosedNumber/page";
import AllResulsts from "./pages/Lottery/AllResulsts/page";
import EditChooseNumber from "./pages/Payment/ChooseNumber/Edit/page";
import WebApp from '@twa-dev/sdk';
import Logout from "./pages/Auth/logout/page";
import MenuPage from "./pages/Menu/page";
import { t } from "i18next";

const App: React.FC = () => {

  const location = useLocation();

  // useEffect(() => {
  //   // Check if inside an iframe
  //   if (window.parent !== window) {
  //     // Send the current route to the parent window
  //     window.parent.postMessage({ path: location.pathname }, "*");
  //   }
  // }, [location]);

  useEffect(() => {
    // Check if inside an iframe
    if (window.parent !== window) {
      // Send the current route to the parent window with a fallback for path
      window.parent.postMessage(
        { path: location.pathname || "/" }, 
        "*"
      );
    }
  }, [location]);

  const mobileWidth = 428; // Adjust as needed for your project
  const [containerWidth, setContainerWidth] = useState("100%");

  const bgTheme1 = "/images/bgs/purple-gradient-bg.jpg";
  const bgPurpleTheme2 = "/images/bgs/purple-bg.jpg";

  useEffect(() => {
    function handleResize() {
      // setContainerWidth( "100%");
      setContainerWidth(window.innerWidth > mobileWidth ? `${mobileWidth}px` : "100%");
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleStyleMessage = (event: MessageEvent) => {
      if (event.origin !== "http://localhost:81") return; // Ensure the origin is trusted

      if (event.data.type === "applyStyles") {
        const style = document.createElement('style');
        style.textContent = event.data.styles;
        document.head.appendChild(style);
      }
    };

    // Listen for messages from the parent window
    window.addEventListener("message", handleStyleMessage);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("message", handleStyleMessage);
    };
  }, []);

  // Redirect to Persian by default if no language prefix is present
  if (!location.pathname.startsWith("/") && !location.pathname.startsWith("/fa")) {
    return <Navigate to={`/fa${location.pathname}`} replace />;
  }

  const isPersian = location.pathname.startsWith("/fa");

  return (
    <div style={{ width: containerWidth, margin: "0 auto" }}>
      {/* <DisableZoom /> */}
      <Routes>
        {/* Layout with language-specific paths */}
        <Route path="/" element={<Layout backgroundImage={bgTheme1} />}>
          <Route index path={isPersian ? "fa" : "/"} element={<Home />} />
          <Route index path={isPersian ? "fa/lottery" : "/lottery"} element={<Lottery />} />
          <Route index path={isPersian ? "fa/access" : "/access"} element={<Access />} />
          <Route index path={isPersian ? "/fa/buy/choosed-number" : "//buy/choosed-number"} element={<ChoosedNumber />} />
          <Route index path={isPersian ? "fa/profile" : "/profile"} element={<Profile />} />
          <Route index path={isPersian ? "fa/menu" : "/menu"} element={<MenuPage />} />
          <Route index path={isPersian ? "fa/buy" : "/buy"} element={<ChooseNumber />} />
          <Route index path={isPersian ? "fa/buy/edit" : "/buy/edit"} element={<EditChooseNumber />} />
          <Route index path={isPersian ? "fa/payment/wallet" : "/payment/wallet"} element={<Wallet />} />
          <Route index path={isPersian ? "fa/terms-of-service" : "/terms-of-service"} element={<TermsOfService />} />
        </Route>

        <Route path="/" element={<Layout backgroundImage={bgTheme1} topTitle={t('result.result')} />}>
          <Route index path={isPersian ? "fa/lottery/my-results" : "/lottery/my-results"} element={<MyResults />} />
          <Route index path={isPersian ? "fa/lottery/all-results" : "/lottery/all-results"} element={<AllResulsts />} />
        </Route>

        <Route path="/" element={<Layout backgroundImage={bgPurpleTheme2} isHideTopIcons={true} />}>
          <Route index path={isPersian ? "fa/auth/step1" : "/auth/step1"} element={<AuthenticationStep1 />} />
          <Route index path={isPersian ? "fa/auth/set-password" : "/auth/set-password"} element={<AuthenticationForget />} />
          <Route index path={isPersian ? "fa/security" : "/security"} element={<Security />} />
        </Route>

        <Route path="/" element={<Layout backgroundImage={bgTheme1} isHideTopIcons={true} />}>
          <Route index path={isPersian ? "fa/auth/step2" : "/auth/step2"} element={<AuthenticationStep2 />} />
        </Route>

        <Route path="/" element={<WhiteLayout backgroundImage={""} isGradient={false} />}>
          <Route index path={isPersian ? "fa/payment/buy-number" : "/payment/buy-number"} element={<BuyNumber />} />
          <Route index path={isPersian ? "fa/payment/receiption/success" : "/payment/receiption/success"} element={<ReceiptionSuccess />} />
          <Route index path={isPersian ? "fa/payment/receiption/failed" : "/payment/receiption/failed"} element={<ReceiptionFailed />} />
          <Route index path={isPersian ? "fa/logout" : "/logout"} element={<Logout />} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
