import React, { useState } from "react";
import { Modal, Slide, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import YButton from "../../../../components/Button/YButton";
import YTextField from "../../../../components/TextField/YTextField";
import { ProfileData } from "../../../../interfaces/auth/profileData";
import { verify } from "../../../../services/authService";
import YTypoGraphy from "../../../../components/Text/YTypography/YTypoGraphy";

interface AuthBottomSheetProps {
  handleUpdate: (message:string,token:string,status:boolean) => void;
}

const AuthBottomSheetForget: React.FC<AuthBottomSheetProps> = ({
  handleUpdate,
}) => {

  const [formState, setFormState] = useState({
    password: "",
    confirm_password: "",
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
 
  const handleSubmit = async () => {

    setLoading(true);
    setError(null);

    try {
      const {  message,token,status } = await verify(localStorage.getItem('username')??"",formState.password,formState.confirm_password);
      console.log('login successful:', message);

      if(status){
        localStorage.setItem('token',token);
      }
      handleUpdate(message,token,status);
    } catch (error) {
      handleUpdate("پسورد یکسان نیست","",false);
      setError('Verification failed. Please check your phone number.');
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <>
      <YTextField
        label="رمز عبور"
        value={formState.password}
        setValue={(value: string) =>
          setFormState((prevState) => ({ ...prevState, password: value }))
        }
        type="password"
      />

      <YTextField
        label="تکرار رمز عبور"
        value={formState.confirm_password}
        setValue={(value: string) =>
          setFormState((prevState) => ({
            ...prevState,
            confirm_password: value,
          }))
        }
        type="password"
      />

      <YTypoGraphy
        variant="body1"
        color={"white"}
        ml={1}
        textAlign={"center"}
        mb={3}
      >
        {" "}
        ثبت نام در مسابقه به معنی تایید شرایط و قوانین مسابقه است
      </YTypoGraphy>

      <YButton
        title="ذخیره"
        color="secondary-outline"
        width={'85vw'}
        height={50}
        onClick={handleSubmit}
      />
    </>
  );
};

export default AuthBottomSheetForget;
