import { Grid }  from "@mui/material";
import "./ReceiptionFailed.css";
import { Image } from "@mui/icons-material";
import YButton from "../../../../components/Button/YButton";
import BottomArrowSvg from "../../../../components/SVG/BottomArrowSvg/BottomArrowSvg";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import SuccessReceiptionSvg from "../../../../components/SVG/payments/receiption/SuccessReceiption";
import FailedReceiptionSvg from "../../../../components/SVG/payments/receiption/FailedReceiptio";
import YTypoGraphy from "../../../../components/Text/YTypography/YTypoGraphy";

const ReceiptionFailed = () => {
  const [formState, setFormState] = useState({
    payID: "5564564",
    price: "2",
    ticket: "1",
    date: "1402/02/11",
  });

  return (
    <>
      <div className="receiption-container">
        <YTypoGraphy
          variant="h5"
          textAlign={"center"}
          fontSize={"1.7rem"}
          position={"absolute"}
          top={30}
          right={"35%"}
        >
          رسید پرداخت
        </YTypoGraphy>

        <FailedReceiptionSvg dir={""} float={""} />

        <YTypoGraphy variant="h5" className="lbl-ticket" fontSize={"1rem"}>
          <span>{formState.ticket} </span>بلیط
        </YTypoGraphy>
        <YTypoGraphy variant="h5" className="lbl-price" fontSize={"1rem"}>
          <span>{formState.price} </span>تتر
        </YTypoGraphy>
        <YTypoGraphy variant="h5" className="lbl-date" fontSize={"1.1rem"}>
          <span>{formState.date} </span>
        </YTypoGraphy>
        <Link to={""} className="lbl-receiption">
          دانلود رسید تراکنش
        </Link>
      </div>

      <Grid
        container
        spacing={2}
        position={"fixed"}
        bottom={"2%"}
        pr={2}
        pl={2}
      >
        <Grid item xs={6}>
          <YButton
            title="برو به خانه"
            color="primary-outline"
            width={194}
            height={50}
          />
        </Grid>

        <Grid item xs={6}>
          <YButton
            title="ارتباط با پشتیبانی"
            color="primary"
            width={194}
            height={50}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ReceiptionFailed;
