// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guessPrizeItemContainer{
    width: 90vw;
    height:auto;
    margin: 0 auto;
    margin-top: 0.35rem;
    /* margin-right: 2.5vw; */
    border-radius: 16px;
    /* backdrop-filter: blur(238.89px); */
    box-shadow: 0px 8px 20px 0px #0000000d;
    /* background-color: #0000000d; */
    border: 1.5px solid #ffa3f63a;
    /* border: 1.5px solid #FFA3F6; */
}



.guessPrizeItemContainer .seperator{
  border-color: #FFFFFF45;
  height: 1px;
}




.guessPrizeItemContainer .winner-emails{
  color: white;
  margin-left: 16px;

}

.guessPrizeItemContainer .winner-row{
  color: white;
  margin-right: 16px;

}



`, "",{"version":3,"sources":["webpack://./src/components/Numbers/Guess/GuessPrizeCard/GuessResultItem/GuessResultItem.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,cAAc;IACd,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,qCAAqC;IACrC,sCAAsC;IACtC,iCAAiC;IACjC,6BAA6B;IAC7B,iCAAiC;AACrC;;;;AAIA;EACE,uBAAuB;EACvB,WAAW;AACb;;;;;AAKA;EACE,YAAY;EACZ,iBAAiB;;AAEnB;;AAEA;EACE,YAAY;EACZ,kBAAkB;;AAEpB","sourcesContent":[".guessPrizeItemContainer{\n    width: 90vw;\n    height:auto;\n    margin: 0 auto;\n    margin-top: 0.35rem;\n    /* margin-right: 2.5vw; */\n    border-radius: 16px;\n    /* backdrop-filter: blur(238.89px); */\n    box-shadow: 0px 8px 20px 0px #0000000d;\n    /* background-color: #0000000d; */\n    border: 1.5px solid #ffa3f63a;\n    /* border: 1.5px solid #FFA3F6; */\n}\n\n\n\n.guessPrizeItemContainer .seperator{\n  border-color: #FFFFFF45;\n  height: 1px;\n}\n\n\n\n\n.guessPrizeItemContainer .winner-emails{\n  color: white;\n  margin-left: 16px;\n\n}\n\n.guessPrizeItemContainer .winner-row{\n  color: white;\n  margin-right: 16px;\n\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
