import React from "react";
import { }  from "@mui/material";
import "./ResultItenNumber.css";
import YTypoGraphy from "../../Text/YTypography/YTypoGraphy";

interface ResultItemNumberProps {
  luckyNumbers: number[];
  userGuess: number[];
  number: number;
  index: number;
  isBlur: boolean;
  defaultColor?: string;
}

const ResultItemNumber: React.FC<ResultItemNumberProps> = ({
  luckyNumbers,
  userGuess,
  number,
  index,
  isBlur,
  defaultColor = "white",
  // defaultColor = "rgba(142, 142, 142, 1)",
}) => {
  return (
    <YTypoGraphy
      className={isBlur ? "blur-text" : ""}
      variant="h5"
      fontWeight={600}
      fontSize={"1.05rem"}
      textAlign={"center"}
      margin={"0 auto"}
      width={32}
    >
      <span
        style={{
          color: userGuess?.includes(number)
            ? "white"
            // ? "rgba(0, 158, 16, 1)"
            : `${defaultColor}`,
        }}
      >
        {number}
      </span>

      {index !== luckyNumbers.length - 1 ? (
        <span
          style={{
            fontSize: "2rem",
            fontWeight: 500,
            marginTop: "8px",
            marginRight:'2px',
            color: `${defaultColor}`,
          }}
        >
          -
        </span>
      ) : (
        <span
          style={{
            fontSize: "2rem",
            fontWeight: 500,
            marginTop: "8px",
          }}
        ></span>
      )}
    </YTypoGraphy>
  );
};

export default ResultItemNumber;
