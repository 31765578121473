import { Grid } from "@mui/material";
import "./AllResulsts.css";
import InfoSvg from "../../../components/SVG/InfoSvg/InfoSvg";
import YButton from "../../../components/Button/YButton";
import NoParticipationSvg from "../../../components/SVG/Results/NoParticipation/NoParticipationSvg";
import React, { useEffect, useState } from "react";
import ResultItem from "../../../components/Results/ResultItems/ResultItem";
import AllResultItem from "../../../components/Results/AllResultItems/AllResultItems";
import FilterSvg from "../../../components/SVG/FilterSvg/FilterSvg";
import { useNavigate } from "react-router-dom";
import BottomSheet from "../../../components/BottomSheet/BottomSheet";
import WinnersBottomSheet from "./WinnersBottomSheet";
import FilterBottomSheet from "./FilterBottomSheet";
import {
  getAllResulsts,
  LotteryResultsResponse,
} from "../../../services/resultsService";
import { toPersianDate } from "../../../utility/dateUtils";
import YTypoGraphy from "../../../components/Text/YTypography/YTypoGraphy";
import { t } from "i18next";

export interface Winner {
  email: string;
}

export interface WinnersByEmail {
  win_1: Winner[];
  win_2: Winner[];
  win_3: Winner[];
  win_4: Winner[];
  win_5: Winner[];
  win_6: Winner[];
}

const AllResulsts = () => {

  const [results, setResults] = useState<any[]>([]);
  const navigate = useNavigate();
  const [winnersByEmail, setWinnersByEmail] = useState<WinnersByEmail>({
    win_1: [],
    win_2: [],
    win_3: [],
    win_4: [],
    win_5: [],
    win_6: [],
  });

  const [openFilter, setOpenFilter] = useState(false);
  const [openWinner, setOpenWinner] = useState(false);
  const [lotteryResults, setLotteryResults] = useState<LotteryResultsResponse>({
    results: [],
    wins3: [],
    wins4: [],
    wins5: [],
    status: true,
  });
  

  async function handleLotteryResults() {
    const fetchedResults: LotteryResultsResponse | null = await getAllResulsts();
  
    if (fetchedResults) {
      setLotteryResults(fetchedResults);
      
      // Extract winners' emails categorized by their win counts
      const winnersEmails = separateWinnersByWinCount(fetchedResults);
      setWinnersByEmail(winnersEmails);
    }
  }

  useEffect(() => {}, []);
  // const navigate = useNavigate();

  useEffect(() => {
    // const initialArray = [{ type: "gold" }, { type: "silver" }];
    // setResults(initialArray);
    handleLotteryResults();
  }, []);

  function filterPage() {
    setOpenFilter(!openFilter);
  }

  function winnersBottomSheet() {
    setOpenWinner(!openWinner);
  }

  function guessNumberPage() {
    navigate("/fa/buy");
  }

  function handleChangeWinTab(link: string) {
    navigate(link);
  }


  const separateWinnersByWinCount = (data: LotteryResultsResponse): WinnersByEmail => {
    const winnersByWinCount: WinnersByEmail = {
      win_1: [],
      win_2: [],
      win_3: [],
      win_4: [],
      win_5: [],
      win_6: [],
    };
  
    data.results.forEach((result) => {
      result.tickets.forEach((ticket) => {
        const winCount = ticket.numbers_wins.length as 1 | 2 | 3 | 4 | 5 | 6;
  
        if (winCount >= 1 && winCount <= 6) {
          const email = ticket.user?.email;
          if (email) {
            winnersByWinCount[`win_${winCount}`].push({ email }); // Push an object with the email
          }
        }
      });
    });
  
    return winnersByWinCount;
  };


  function handleCallback() {}

  return (
    <>
      <Grid container spacing={2} mb={0} p={2}>
        <Grid item xs={6}>
          <YButton
            mt={0}
            color={"secondary-outline-not-selected"}
            align={"center"}
            radius={"radius-primary"}
            width={"45vw"}
            height={53}
            mb={0.5}
            title={t("result.my_results")}
            onClick={() => handleChangeWinTab("/fa/lottery/my-results")}
          />
        </Grid>

        <Grid item xs={6}>
          <YButton
            mt={0}
            color={"secondary-outline"}
            align={"center"}
            radius={"radius-primary"}
            width={"45vw"}
            height={53}
            mb={0.5}
            title={t("result.all_results")}
            onClick={() => handleChangeWinTab("/fa/lottery/all-results")}
          />
        </Grid>
      </Grid>

      {lotteryResults?.results?.length > 0 ? (
        <>
          {lotteryResults.results.map((item, index) => {
            // Get emails for each win count
            const winnersEmails = [
              ...winnersByEmail.win_1,
              ...winnersByEmail.win_2,
              ...winnersByEmail.win_3,
              ...winnersByEmail.win_4,
              ...winnersByEmail.win_5,
              ...winnersByEmail.win_6,
            ];

            return (
              <AllResultItem
                key={index}
                date={toPersianDate(new Date(item.lottery_date))}
                luckyNumbers={item?.lottery_numbers}
                amountWon={item.winners_total_amount}
                WonCount={item.number_of_wins}
                type={"gold"}
                winners={[
                  item.first_win,
                  item.second_win,
                  item.third_win,
                  item.forth_win,
                  item.fifth_win,
                  item.sixth_win,
                ]}
                winnersEmails={winnersByEmail} // Extract emails
              />
            );
          })}
        </>
      ) : (
        <>
          <Grid textAlign={"center"} mt={15}>
            <NoParticipationSvg dir={""} float={""} />
          </Grid>

          <YTypoGraphy
            variant="body1"
            color={"white"}
            textAlign={"center"}
            pr={5}
            pl={5}
          >
            {t("result.you_have_not_contributed_yet")}
          </YTypoGraphy>
          <YTypoGraphy
            variant="body1"
            color={"white"}
            textAlign={"center"}
            pr={5}
            pl={5}
          >
            {t("result.guess_number_and_try_your_lock")}
          </YTypoGraphy>

          <YButton
            mt={2}
            color={"secondary-outline"}
            align={"center"}
            radius={"radius-primary"}
            width={"45vw"}
            height={50}
            mb={0.5}
            title={t("guess_numbers")}
            onClick={guessNumberPage}
          />
        </>
      )}

      {/* <YButton
        mt={2}
        color={"secondary-outline"}
        align={"center"}
        radius={"radius-primary"}
        width={"45vw"}
        height={50}
        mb={0.5}
        title={t('result.the_winners')}
        onClick={winnersBottomSheet}
      /> */}

      <BottomSheet
        title={t("result.the_winners")}
        description=""
        open={openWinner}
        setOpen={setOpenWinner}
        isDark={true}
        isTransparent={true}
        children={
          <WinnersBottomSheet
            handleUpdate={handleCallback}
            data={lotteryResults}
          />
        }
      />

      <BottomSheet
        title={t("result.filter")}
        description=""
        open={openFilter}
        setOpen={setOpenFilter}
        isDark={true}
        isTransparent={true}
        children={<FilterBottomSheet handleUpdate={handleCallback} />}
      />
    </>
  );
};

export default AllResulsts;
