import { Grid } from "@mui/material";
import React from "react";
import "./banner.css";
import YTypoGraphy from "../Text/YTypography/YTypoGraphy";

interface BannerSectionProps {
  width?: string | number;
  height?: string | number;
  mr?: string | number; // margin-right
  mt?: string | number; // margin-top
  ml?: string | number; // margin-left
  mb?: string | number; // margin-bottom
  pt?: string | number; // padding-top
  pb?: string | number; // padding-bottom
  pr?: string | number; // padding-right
  pl?: string | number; // padding-left;
}

const BannerSection: React.FC<BannerSectionProps> = ({
  width,
  height,
  mr,
  mt,
  ml,
  mb,
  pt,
  pb,
  pr,
  pl,
}) => {
  return (
    <Grid
      container
      // className="banner-container primary-gradient-bg"
      sx={{
        width,
        height,
        marginRight: mr,
        marginTop: mt,
        marginLeft: ml,
        marginBottom: mb,
        paddingTop: pt,
        paddingBottom: pb,
        paddingRight: pr,
        paddingLeft: pl,
      }}
    >
      {/* <Grid item xs={6}> */}

      {localStorage.getItem("selectedLanguage") == "fa" ? (
        <img
          src="/images/banners/1.gif"
          style={{
            borderRadius: 32,
            margin: "0 auto",
            height: "143px",
            width: "90vw",
          }}
        />
      ) : (
        <img
          src="/images/banners/2.gif"
          style={{
            borderRadius: 32,
            margin: "0 auto",
            height: "143px",
            width: "90vw",
          }}
        />
      )}

      {/* <img src="/images/icons/jet.png" style={{ paddingRight: '1.5rem' }} /> */}
      {/* </Grid> */}
      {/* <Grid item xs={6}>
        <YTypoGraphy
          variant="h4"
          fontSize={"1.7rem !important"}
          color={"white"}
          mt={1}
          width={"120px"}
          textAlign={"right"}
        >
          بازی کنید، برنده شوید، رویا بسازید!
        </YTypoGraphy>
      </Grid> */}
    </Grid>
  );
};

export default BannerSection;
