import React, { useState } from "react";
import { Modal, Slide, Box, Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import YButton from "../../../components/Button/YButton";
import YTextField from "../../../components/TextField/YTextField";
import { ProfileData } from "../../../interfaces/auth/profileData";
import YTypoGraphy from "../../../components/Text/YTypography/YTypoGraphy";
import { t } from "i18next";


interface ChoosedPayBottomSheetProps {
  handleUpdate: () => void;
}

const ChoosedPayBottomSheet: React.FC<ChoosedPayBottomSheetProps> = ({
  handleUpdate,
}) => {
  const [formState, setFormState] = useState<ProfileData>({
    gender: "",
    email: "",
    phone: "",
    country: "",
  });

  function Update() {
    handleUpdate();
    console.log(formState);
  }

  return (
    <>
      <YTypoGraphy
        variant="body1"
        textAlign={"center"}
        color={"rgba(255, 255, 255, 1)"}
      >
      {t('choose_number.if_you_dont_know_you_should_choose')}
      </YTypoGraphy>

      <Grid container mt={3} spacing={2}>
        <Grid item xs={6}>
          <YButton
            title={t('choose_number.continue_payment')}
            color="secondary"
            width={194}
            height={50}
            border="#000 solid"
            onClick={Update}
          />
        </Grid>

        <Grid item xs={6}>
          <YButton
            title={t('choose_number.i_dont_know')}
            color="secondary"
            width={194}
            height={50}
            border="#000 solid"
            onClick={Update}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ChoosedPayBottomSheet;
