import { Grid } from "@mui/material";
import "./Wallet.css";
import InfoSvg from "../../../components/SVG/InfoSvg/InfoSvg";
import YButton from "../../../components/Button/YButton";
import NoParticipationSvg from "../../../components/SVG/Results/NoParticipation/NoParticipationSvg";
import React, { useEffect, useState } from "react";
import FilterSvg from "../../../components/SVG/FilterSvg/FilterSvg";
import { useNavigate } from "react-router-dom";
import BottomSheet from "../../../components/BottomSheet/BottomSheet";
import WithDrawBottomSheet, {
  formWithdrawStructure,
} from "./WithDrawBottomSheet";
import DepositBottomSheet from "./DepositBottomSheet";
import BalanceCardSvg from "../../../components/Payment/BalanceCardSvg/BalanceCardSvg";
import WalletTransitionItem from "../../../components/Payment/WalletTransitionItem/WalletTransitionItem";
import { getProfile, UserProfileResponse } from "../../../services/authService";
import {
  getTransctions,
  addDepositWallet,
  TransactionsResponse,
  addWithdrawWallet,
} from "../../../services/paymentService";
import NotifyAlert from "../../../components/NotifyAlert/NotifyAlert";
import YTypoGraphy from "../../../components/Text/YTypography/YTypoGraphy";
import { useTranslation } from "react-i18next"; // Updated import for i18next

const WalletPage = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const [results, setResults] = useState<any[]>([]);
  const [openWithDraw, setOpenWithDraw] = useState(false);
  const [openDeposit, setOpenDeposit] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState<UserProfileResponse | null>(null);
  const [transactionsData, setTransactionsData] =
    useState<TransactionsResponse>();
  const [showAlert, setShowAlert] = useState({
    isEnable: false,
    message: "",
    status: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    getProfileFetch();
    getTransactionsFetch();
    setLoading(true);
    setError(null);
    localStorage.setItem("last_url", "/fa/payment/wallet");
  }, []);

  const getProfileFetch = async () => {
    try {
      const profileData = await getProfile();
      setProfile(profileData);
    } catch (error) {
      console.error("Error fetching profile:", error);
    } finally {
      setLoading(false);
    }
  };

  const getTransactionsFetch = async () => {
    try {
      const transactionsData = await getTransctions();
      setTransactionsData(transactionsData);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setLoading(false);
    }
  };

  function withdrawBottomSheet() {
    setOpenWithDraw(!openWithDraw);
  }

  function depositBottomSheet() {
    setOpenDeposit(!openDeposit);
  }

  function guessNumberHandle() {
    navigate("/fa/buy");
  }

  async function depositHandleCallback(amount: string) {
    setOpenDeposit(false);
    if (amount === "" || parseFloat(amount) < 1) {
      setShowAlert({
        isEnable: true,
        message: t("wallet.deposit_amount_error"),
        status: false,
      });
    } else {
      setShowAlert({
        isEnable: true,
        message: t("wallet.redirecting_to_payment"),
        status: true,
      });

      setTimeout(async () => {
        const depositWallet = await addDepositWallet(amount);
        window.location.href = depositWallet.invoice_url;
        setShowAlert({ isEnable: false, message: "", status: true });
      }, 2000);
    }
  }

  async function withdrawHandleCallback(data: formWithdrawStructure) {
    setOpenWithDraw(false);
    if (1 >= parseFloat(data.amount)) {
      setShowAlert({
        isEnable: true,
        message: t("wallet.withdraw_amount_min"),
        status: false,
      });
    } else if (
      parseFloat((profile?.user.balance_tether ?? 0).toString()) <=
      parseFloat(data.amount)
    ) {
      setShowAlert({
        isEnable: true,
        message: t("wallet.withdraw_amount_exceeds_balance"),
        status: false,
      });
    } else if (data.address === "") {
      setShowAlert({
        isEnable: true,
        message: t("wallet.withdraw_address_error"),
        status: false,
      });
    } else if (data.amount === "" || parseFloat(data.amount) < 1) {
      setShowAlert({
        isEnable: true,
        message: t("wallet.withdraw_amount_error"),
        status: false,
      });
    } else {
      const depositWallet = await addWithdrawWallet(data.amount, data.address);
      if (!depositWallet.status) {
        setShowAlert({
          isEnable: true,
          message: t("wallet.withdraw_request_error"),
          status: false,
        });
      } else {
        setShowAlert({
          isEnable: true,
          message: t("wallet.withdraw_request_success"),
          status: true,
        });
      }
    }
    getTransactionsFetch();
    setTimeout(async () => {
      setShowAlert({ isEnable: false, message: "", status: true });
    }, 2000);
  }

  return (
    <>
      {showAlert.isEnable && (
        <NotifyAlert
          dir={""}
          float={""}
          color={showAlert.status ? `success` : "danger"}
          content={showAlert.message}
        />
      )}

      <Grid container mt={10} display={"block"} textAlign={"center"}>
        <Grid display={"inline-flex"}>
          <YTypoGraphy
            variant="h3"
            fontWeight={700}
            fontSize={"1.3rem"}
            textAlign={"center"}
            color={"white"}
            mt={0}
            mb={0}
            mr={2}
            pl={1}
          >
            {t("wallet.wallet")}
          </YTypoGraphy>
        </Grid>

        <Grid
          container
          className="balanceContainer"
          sx={{ position: "relative" }}
        >
          <YTypoGraphy variant="h4" className="amount">
            {profile?.user?.balance_tether?.toFixed(2)}
            <span>USDT</span>
          </YTypoGraphy>

          <YTypoGraphy variant="h5" className="balance">
            {t("wallet.wallet_balance")}
          </YTypoGraphy>

          <Grid
            container
            className="DepositWithDrawContainer"
            spacing={2}
            mb={0}
            p={2}
          >
            <Grid item xs={6}>
              <YButton
                mt={0}
                color={"deposit-withdraw"}
                align={"center"}
                radius={"radius-secondary"}
                width={"38vw"}
                height={42}
                mb={0.5}
                title={t("wallet.charge")}
                onClick={depositBottomSheet}
              />
            </Grid>

            <Grid item xs={6}>
              <YButton
                mt={0}
                color={"deposit-withdraw"}
                align={"center"}
                radius={"radius-secondary"}
                width={"38vw"}
                height={42}
                mb={0.5}
                title={t("wallet.withdraw")}
                onClick={withdrawBottomSheet}
              />
            </Grid>
          </Grid>

          <BalanceCardSvg dir={""} float={""} />
        </Grid>
      </Grid>

      <Grid container pr={2} mt={2} pl={2}>
        <Grid item xs={11}>
          <YTypoGraphy variant="h5" fontSize={"1rem"} color={"white"}>
            {t("transaction.transaction_list")}
          </YTypoGraphy>
        </Grid>
        <Grid item mr={0.5} textAlign={"left"}>
          <div>
            <FilterSvg dir={""} float={""} color={"white"} />
          </div>
        </Grid>
      </Grid>

      {(transactionsData?.transactions?.length ?? 0) > 0 ? (
        <>
          {transactionsData!.transactions.map((item, index) => (
            <WalletTransitionItem
              key={index}
              transID={item.transID}
              date={item.transaction_date}
              amount={item.amount}
              type={item.type}
              gateway={item.gateway}
              pay_address={item.pay_address}
              transaction_failed_reason={item.transaction_failed_reason}
              transaction_status={item.transaction_status}
              invoice_url={item.invoice_url}
            />
          ))}
        </>
      ) : (
        <>
          <Grid textAlign={"center"} mt={15}>
            <NoParticipationSvg dir={""} float={""} />
          </Grid>

          <YTypoGraphy
            variant="body1"
            textAlign={"center"}
            pr={5}
            pl={5}
            color={"white"}
          >
            {t("wallet.no_participation")}
          </YTypoGraphy>

          <YButton
            mt={2}
            color={"primary"}
            align={"center"}
            radius={"radius-primary"}
            width={"45vw"}
            height={50}
            mb={0.5}
            onClick={guessNumberHandle}
            title={t("wallet.start_guessing")}
          />
        </>
      )}

      <BottomSheet
        title={t("wallet.deposit")}
        description={t('short_lorem')}
        open={openDeposit}
        setOpen={setOpenDeposit}
        isDark={true}
        hasWarning={true}
        isTransparent={false}
        children={
          <DepositBottomSheet
            depositHandleCallback={depositHandleCallback}
            balance={parseFloat(
              profile?.user?.balance_tether?.toFixed(2) ?? "0"
            )}
          />
        }
      />

      <BottomSheet
        title={t("wallet.withdraw")}
        description={t('short_lorem')}
        open={openWithDraw}
        setOpen={setOpenWithDraw}
        isDark={true}
        hasWarning={true}
        isTransparent={false}
        children={
          <WithDrawBottomSheet
            withdrawHandleCallback={withdrawHandleCallback}
            balance={parseFloat(
              profile?.user?.balance_tether?.toFixed(2) ?? "0"
            )}
          />
        }
      />
    </>
  );
};

export default WalletPage;
