// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-item{

    background: linear-gradient(180deg, rgba(229, 66, 255, 0.5) 0%, rgba(137, 40, 153, 0.5) 100%);
    -webkit-backdrop-filter: blur(50px);
            backdrop-filter: blur(50px);
    border-radius: 11px;
    box-sizing: border-box;
    border: 1px #E542FF6E solid;
}/* Rectangle 39587 */

/* Rectangle 39587 */
`, "",{"version":3,"sources":["webpack://./src/components/Menu/navDrawer/mobileMenu/MenuItem/mobileMenu.css"],"names":[],"mappings":"AAAA;;IAEI,6FAA6F;IAC7F,mCAA2B;YAA3B,2BAA2B;IAC3B,mBAAmB;IACnB,sBAAsB;IACtB,2BAA2B;AAC/B,CAAC,oBAAoB;;AAErB,oBAAoB","sourcesContent":[".menu-item{\n\n    background: linear-gradient(180deg, rgba(229, 66, 255, 0.5) 0%, rgba(137, 40, 153, 0.5) 100%);\n    backdrop-filter: blur(50px);\n    border-radius: 11px;\n    box-sizing: border-box;\n    border: 1px #E542FF6E solid;\n}/* Rectangle 39587 */\n\n/* Rectangle 39587 */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
