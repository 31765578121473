import { Socket } from "socket.io-client";
import apiService from "./apiService"; // Adjust the path as needed

interface addChooseNumberResponse {
  status: boolean;
  message: string;
}

interface choosedNumberPayload {
  status: "WAITING_TO_PAY" | "ALL";
}

export interface ChosenNumber {
  id: number;
  number_1: number;
  number_2: number;
  number_3: number;
  number_4: number;
  number_5: number;
  number_6: number;
  lottery_id: number;
  user_id: number;
  numbers: number[];
}

export interface activeLottery {
  id?: number;
  lottery_date: Date;
  lottery_status: string;
  default_number: number;
  ticket_price: number;
}

interface choosedNumberList {
  chosenNumbers: ChosenNumber[];
  activeLottery: activeLottery;
  status: boolean;
}

export interface ChosenNumbers {
  id: number;
  number_1: number;
  number_2: number;
  number_3: number;
  number_4: number;
  number_5: number;
  number_6: number;
  lottery_id: number;
  user_id: number;
  numbers: number[];
}

export interface getSingleNumberResponse {
  chosenNumbers: ChosenNumbers;
  activeLottery: activeLottery;
  status: boolean;
}

interface ActiveLottery {
  id: number;
  lottery_date: string;
  lottery_status: "WATING_FOR_START_LOTTERY" | string; // Adjust based on other possible statuses
  default_number: number;
  ticket_price: number;
  first_prc: number;
  second_prc: number;
  third_prc: number;
  forth_prc: number;
  fifth_prc: number;
  sixth_prc: number;
  first_win: number;
  second_win: number;
  third_win: number;
  forth_win: number;
  fifth_win: number;
  sixth_win: number;
}

interface TotalPrice {
  amount: number;
}

interface PrizeAmounts {
  firstPrizeAmount: number;
  secondPrizeAmount: number;
  thirdPrizeAmount: number;
  forthPrizeAmount: number;
  fifthPrizeAmount: number;
  sixthPrizeAmount: number;
}

interface ActiveLotteryResponse {
  activeLottery: ActiveLottery;
  totalPrice: TotalPrice;
  prizeAmounts: PrizeAmounts;
  status: boolean;
}

interface StartLotteryResponse{
  message:string;
  status:boolean;
}

export interface GerLotteryDefaultNumber {
  message: string;
  status: boolean;
  hasWaitingTicket: boolean;
  data?: GetLotteryData;
}

interface GetLotteryData {
  id: number;
  default_number: number;
  selected_tickets: Ticket[];
}

interface Ticket {
  id: string;
  numbers: number[];  // Array of numbers (number_1 to number_6)
}


export const getActiveLottery =
  async (): Promise<ActiveLotteryResponse | null> => {
    try {
      const response = await apiService.get<ActiveLotteryResponse>(
        "/api/app/lottery/active"
      );
      return response; // Assuming `response.data` contains the UserlotteryResponse
    } catch (error) {
      console.error("Update lottery failed:", error);
      return null;
      // return null;// Provide a more user-friendly error message
    }
  };

export const getSingleChoosedNumber = async (
  number_id: number
): Promise<getSingleNumberResponse | null> => {
  try {
    const response = await apiService.get<getSingleNumberResponse>(
      "/api/app/lottery/choosed-number/" + number_id
    );
    return response; // Assuming `response.data` contains the UserlotteryResponse
  } catch (error) {
    console.error("Update lottery failed:", error);
    return null; // Provide a more user-friendly error message
  }
};

export const getChoosedNumbers = async (
  payload: choosedNumberPayload
): Promise<choosedNumberList | null> => {
  try {
    const response = await apiService.get<choosedNumberList>(
      "/api/app/lottery/choosed-numbers",
      payload
    );
    return response; // Assuming `response.data` contains the UserlotteryResponse
  } catch (error) {
    console.error("Update lottery failed:", error);
    return null; // Provide a more user-friendly error message
  }
};

export const addChoosedNumber = async (
  numbers: number[]
): Promise<addChooseNumberResponse | null> => {
  try {
    const response = await apiService.post<addChooseNumberResponse>(
      "/api/app/lottery/choosed-number",
      { numbers: numbers }
    );
    return response; // Assuming `response.data` contains the UserlotteryResponse
  } catch (error) {
    console.error("Update lottery failed:", error);
    return null; // Provide a more user-friendly error message
  }
};

export const editChoosedNumber = async (
  number_id: number,
  numbers: number[]
): Promise<addChooseNumberResponse | null> => {
  try {
    const response = await apiService.put<addChooseNumberResponse>(
      "/api/app/lottery/choosed-number/" + number_id,
      { numbers: numbers }
    );
    return response; // Assuming `response.data` contains the UserlotteryResponse
  } catch (error) {
    console.error("Update lottery failed:", error);
    return null; // Provide a more user-friendly error message
  }
};

export const deleteChoosedNumber = async (
  number_id: number
): Promise<addChooseNumberResponse | null> => {
  try {
    const response = await apiService.del<addChooseNumberResponse>(
      "/api/app/lottery/choosed-number/" + number_id
    );
    return response; // Assuming `response.data` contains the UserlotteryResponse
  } catch (error) {
    console.error("Update lottery failed:", error);
    return null; // Provide a more user-friendly error message
  }
};

export const startLottery = async (): Promise<StartLotteryResponse | null> => {
  try {
    const response = await apiService.get<StartLotteryResponse>(
      "/api/admin/lottery/start-lottery"
    );
    return response; // Assuming `response.data` contains the UserlotteryResponse
  } catch (error) {
    console.error("Update lottery failed:", error);
    return null; // Provide a more user-friendly error message
  }
};

export const getLotteryDefaultNumber = async (): Promise<GerLotteryDefaultNumber | null> => {
  try {
    const response = await apiService.get<GerLotteryDefaultNumber>(
      "/api/app/lottery/choosed-number/default_number"
    );
    return response; // Assuming `response.data` contains the UserlotteryResponse
  } catch (error) {
    console.error("Update lottery failed:", error);
    return null; // Provide a more user-friendly error message
  }
};


