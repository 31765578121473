// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header.prizeValue {
    /* margin-top: 1rem !important; */
    /* margin-right: 2rem; */
    /* float: right; */
    /* position: absolute; */
    /* right: 24px; */
}

.prizeValue {
    
}

.prizeValue .greenDot {
    position: relative;
    vertical-align: middle;
}
`, "",{"version":3,"sources":["webpack://./src/components/Text/GreenLightText/GreenLightText.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,wBAAwB;IACxB,kBAAkB;IAClB,wBAAwB;IACxB,iBAAiB;AACrB;;AAEA;;AAEA;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;AAC1B","sourcesContent":[".header.prizeValue {\n    /* margin-top: 1rem !important; */\n    /* margin-right: 2rem; */\n    /* float: right; */\n    /* position: absolute; */\n    /* right: 24px; */\n}\n\n.prizeValue {\n    \n}\n\n.prizeValue .greenDot {\n    position: relative;\n    vertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
