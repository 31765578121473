import { Button } from "@mui/base";
import React from "react";
import "./GreenLightText.css";
import Grid from "@mui/material/Grid";
import YTypoGraphy from "../YTypography/YTypoGraphy";

interface GreenLightTextProps {
  title: string;
  isLightOn?: boolean; // Make the prop optional
  isHeader?: boolean;
  fontSize?: string;
}

const GreenLightText: React.FC<GreenLightTextProps> = ({
  title,
  isLightOn = true,
  isHeader = true,
  fontSize = "0.65rem",
}) => {
  return (
    <Grid>
      {isHeader ? (
        <YTypoGraphy
          variant="h4"
          color={"white"}
          className="header prizeValue"
          fontWeight={950}
        >
          {isLightOn && (
            <img className="greenDot" src={"/images/svgs/greenRadial.svg"} />
          )}{" "}
          {title}
        </YTypoGraphy>
      ) : (
        <YTypoGraphy
          variant="body1"
          color={"white"}
          fontSize={fontSize}
          className="prizeValue"
          fontWeight={950}
        >
          {isLightOn && (
            <img className="greenDot" src={"/images/svgs/greenRadial.svg"} />
          )}{" "}
          {title}
        </YTypoGraphy>
      )}
    </Grid>
  );
};

export default GreenLightText;
