import { Grid }  from "@mui/material";
import "./ReceiptionSuccess.css";
import { Image } from "@mui/icons-material";
import YButton from "../../../../components/Button/YButton";
import BottomArrowSvg from "../../../../components/SVG/BottomArrowSvg/BottomArrowSvg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SuccessReceiptionSvg from "../../../../components/SVG/payments/receiption/SuccessReceiption";
import { checkVerifyTransaction } from "../../../../services/paymentService";
import { ClipLoader } from "react-spinners";
import NotifyAlert from "../../../../components/NotifyAlert/NotifyAlert";
import { toPersianDate } from "../../../../utility/dateUtils";
import YTypoGraphy from "../../../../components/Text/YTypography/YTypoGraphy";
import { t } from "i18next";

const ReceiptionSuccess = () => {
  // http://localhost:3000/fa/payment/receiption/success?NP_id=6331521139
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const NP_id = searchParams.get("NP_id");
  const [showAlert, setShowAlert] = useState({
    isEnable: false,
    message: "",
    status: false,
  });

  const [formState, setFormState] = useState({
    payID: "",
    price: 0,
    description: "",
    date: "",
    tickets:0,
    isConfirm: false,
  });

  async function confirmTransction(pay_id: string | null) {
    const transation = await checkVerifyTransaction(parseInt(pay_id ?? "0"));

    
    setFormState((prevState) => ({
      ...prevState,
      payID: transation.data.pay_payment_id,
      price: transation.data.amount,
      tickets: transation.data?.ticket?.id!=null ?1:0,
      date: transation.data.transaction_date?toPersianDate(new Date(transation.data.transaction_date)):'',
      description:
        transation.data.type === "CHARGE_WALLET_TETHER" ? "شارژ کیف پول" : "",
    }));

    setShowAlert({
      isEnable: true,
      message: transation.message,
      status: transation.status,
    });

    setFormState((prevState) => ({
      ...prevState,
      isConfirm: true,
    }));

    setTimeout(async () => {
      setShowAlert({ isEnable: false, message: "", status: true });
    }, 3500);
  }

  function handleGoAfterBuyRoute() {
    navigate(localStorage.getItem("last_url") ?? "");
  }

  useEffect(() => {
    if(NP_id)
      confirmTransction(NP_id);
    // confirmTransction();
  }, []);

  return (
    <>
      {showAlert.isEnable && (
        <NotifyAlert
          dir={""}
          float={""}
          color={showAlert.status ? `success` : "danger"}
          content={showAlert.message}
        />
      )}

      {!formState.isConfirm ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ClipLoader color="#000" size={70} />
        </div>
      ) : (
        <>
          <div className="receiption-container">
            <YTypoGraphy
              variant="h5"
              textAlign={"center"}
              fontSize={"1.7rem"}
              position={"absolute"}
              top={30}
              right={"35%"}
            >
              رسید پرداخت
            </YTypoGraphy>

            <SuccessReceiptionSvg dir={""} float={""} />

            <YTypoGraphy variant="h5" className="lbl-ticket" fontSize={"1rem"}>
              <span>{formState.tickets!==0?formState.tickets+" بلیط":formState.description} </span>
            </YTypoGraphy>
            <YTypoGraphy
              variant="body1"
              className="lbl-payID"
              fontSize={"1.1rem"}
            >
              شناسه پرداخت: <span>{formState.payID}</span>
            </YTypoGraphy>
            <YTypoGraphy variant="h5" className="lbl-price" fontSize={"1rem"}>
              <span>{formState.price} </span>تتر
            </YTypoGraphy>
            <YTypoGraphy variant="h5" className="lbl-date" fontSize={"1.1rem"}>
              <span>{formState.date} </span>
            </YTypoGraphy>
            <Link to={""} className="lbl-receiption">
              دانلود رسید تراکنش
            </Link>
          </div>

          <Grid
            container
            spacing={2}
            position={"fixed"}
            bottom={"2%"}
            pr={2}
            pl={2}
          >
            {/* <Grid item xs={6}>
                <YButton
                  title="برو به خانه"
                  color="primary-outline"
                  width={194}
                  height={50}
                />
              </Grid>

              <Grid item xs={6}>
                <YButton
                  title=t('result.increase_guess')
                  color="primary"
                  width={194}
                  height={50}
                />
              </Grid> */}
            <Grid item xs={6}>
              <YButton
                title={t('continue')}
                color="primary-outline"
                width={'92vw'}
                height={50}
                onClick={handleGoAfterBuyRoute}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default ReceiptionSuccess;
