import React, { useEffect, useState } from "react";

import CountUp from "react-countup";
import "./CounterPrizeComponent.css";
import YTypoGraphy from "../../Text/YTypography/YTypoGraphy";


interface CounterPrizeComponentProps {
  targetNumber: number | string;
  isLottery?: boolean;
  isMainPage?: boolean;
  fontSize?: number;
  hasAnimation?: boolean;
}


const CounterPrizeComponent: React.FC<CounterPrizeComponentProps> = ({
  targetNumber,
  isLottery,
  isMainPage = false,
  fontSize = 28,
  hasAnimation = true,
}) => {
  const [displayNumber, setDisplayNumber] = useState(1);
  const [isCounting, setIsCounting] = useState(true);

  useEffect(() => {
    if (!hasAnimation) {
      setIsCounting(false);
    }
    let count = 0;
    const interval = setInterval(() => {
      setDisplayNumber((prev) => (prev < 50 ? prev + 1 : 1));
      count++;
      if (count >= 100) {
        clearInterval(interval);
        setIsCounting(false);
      }
    }, 10); // Adjust the speed as needed

    return () => clearInterval(interval);
  }, []);

  const blurEffect = targetNumber == 0 && hasAnimation ? "blur-effect" : ""; // Add blur class when targetNumber is not 0

  return (
    <div
      className={`counterContainer gradient-border mb-2 ${
        (targetNumber !== 0 || isMainPage) && fontSize == 28
          ? "bg-blue"
           : (targetNumber !== 0 && hasAnimation) || (!isMainPage && fontSize == 25)
          ? "bg-blue-lottery-page"
          : targetNumber !== 0 || (isMainPage && fontSize == 40)
          ? "bg-blue-medium"
          : ""
      }`}
    >
      <YTypoGraphy
        variant="h2"
        color={"white"}
        className={`counter-number ${blurEffect}`} // Apply blurEffect class conditionally
        fontSize={`${fontSize}px !important`}
        fontFamily={"Poppins !important"}
        fontWeight={700}
        textAlign={"center"}
        display={"block"}
        position={"relative"}
      >
        {isCounting ? (
          displayNumber.toString().padStart(2, "0") // Ensure two-digit display
        ) : typeof targetNumber == "number" && hasAnimation ? (
          <CountUp start={displayNumber} end={targetNumber} duration={2} />
        ) : typeof targetNumber == "number" ? (
          <>{targetNumber}</>
        ) : (
          "$"
        )}
      </YTypoGraphy>
    </div>
  );
};

export default CounterPrizeComponent;
