import React, { Children, useState } from "react";
import { Modal, Slide, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningSvg from "../SVG/WarningSvg/WarningSvg";
import YTypoGraphy from "../Text/YTypography/YTypoGraphy";

interface BottomSheetProps {
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  description?: string;
  isDark?: boolean;
  isTransparent?: boolean;
  hasWarning?: boolean;
}

const BottomSheet: React.FC<BottomSheetProps> = ({
  children,
  open,
  setOpen,
  title,
  description,
  isDark = false,
  isTransparent = false,
  hasWarning = false,
}) => {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      hideBackdrop={!isDark} // Disables the backdrop
      sx={{}}
    >
      {/* border-top: #fff 1px solid; */}
      {/* bgcolor: 'rgba(0, 0, 0, 0.4)' */}
      {/* backdropFilter:'8px',  */}
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            bgcolor: `${isTransparent ? "#e542ff2b" : "#481043a3"}`,
            // bgcolor: `${isTransparent ? "#e542ff2b" : "#E542FF6E"}`,
            // bgcolor: `${isTransparent ? "rgba(0, 0, 0, 0.56)" : "#343434"}`,
            backdropFilter: "blur(16px)", // Apply backdrop filter here
            boxShadow: 32,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            borderRadius: "32px 32px 0 0",
            borderTop: "0.5px #fff solid",
            p: 3,
          }}
        >
          <Box
            sx={{
              width: 50,
              height: 6,
              backgroundColor: "#ccc",
              borderRadius: 3,
              margin: "0 auto",
              mb: 2,
            }}
          ></Box>

          <YTypoGraphy
            id="modal-title"
            variant="h6"
            color={"white"}
            component="h2"
            align="center"
          >
            {title}
          </YTypoGraphy>

          {hasWarning ? <WarningSvg dir={""} float={""} /> : ""}

          <YTypoGraphy
            id="modal-title"
            variant="body1"
            color={"rgba(184, 184, 184, 1)"}
            component="h2"
            align="right"
          >
            {description}
          </YTypoGraphy>

          <Box component="form" sx={{ mt: 2 }}>
            {children}
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default BottomSheet;
