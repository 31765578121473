import React, { useEffect } from 'react';
import './TopLotteryValue.css';
import PrizePersianCurrency from '../Guess/PrizePersianCurrency/PrizePersianCurrency';
import YTypoGraphy from '../../Text/YTypography/YTypoGraphy';

interface TopLotteryValueProps {
  targetNumber: number;
  isLottery?: boolean;
  mt?: string | number;
  mb?: string | number;
  mr?: string | number;
  ml?: string | number;
  pt?: string | number;
  pb?: string | number;
  pr?: string | number;
  pl?: string | number;
  width?: string | number;
  height?: string | number;
}

const TopLotteryValue: React.FC<TopLotteryValueProps> = ({ 
  targetNumber, 
  mt, 
  mb, 
  mr, 
  ml, 
  pt, 
  pb, 
  pr, 
  pl, 
  width, 
  height 
}) => {
  useEffect(() => {
    // Add any side effects or logic if necessary
  }, []);

  const cardStyle: React.CSSProperties = {
    marginTop: mt,
    marginBottom: mb,
    marginRight: mr,
    marginLeft: ml,
    paddingTop: pt,
    paddingBottom: pb,
    paddingRight: pr,
    paddingLeft: pl,
    width: width,
    height: height,
  };

  return (
    <div className="lottery-value-container" style={cardStyle}>
      <section className="bottom-card">
        <YTypoGraphy color={'white'} fontSize={'1.5rem'} textAlign={'center'} mt={2}>
        ارزش نقدی جکپـــــــات(جایزه بزرگ)
        </YTypoGraphy>
        <hr />
        <PrizePersianCurrency 
          amount={targetNumber} 
          hasDollar={true}
          align="center" 
          primaryFontSize={'3rem !important'} 
          secondaryFontSize={'1rem'} 
          secondaryTextAlign="center" 
        />
      </section>
    </div>
  );
};

export default TopLotteryValue;
