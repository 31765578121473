// src/i18n/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translation files
import translationEN from '../locales/app/en/translation.json';
import translationFA from '../locales/app/fa/translation.json';

// Get the saved language from localStorage
const savedLanguage = localStorage.getItem('selectedLanguage') || 'en'; // Default to English if none found

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      fa: {
        translation: translationFA,
      },
    },
    lng: savedLanguage, // Set the initial language to the saved language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18n;
