import React from "react";
import { Box, Paper, Card, Grid } from "@mui/material";

import YButton from "../../Button/YButton";
import { useNavigate } from "react-router-dom";
import YTypoGraphy from "../../Text/YTypography/YTypoGraphy";
import { t } from "i18next";
import i18n from "../../../i18n/i18n";
import ResultItemNumber from "../ResultItems/ResultItemNumber";
import { Ticket } from "../../../services/resultsService";

interface ResultItem2Props {
  date: string;
  luckyNumbers: number[];
  // userChoosedNumbers?: number[];
  // userGuess: number[];
  // amountWon: number;
  // numberOfCorrectGuesses: number;
  lotteryStatus: // | "WAITING_FOR_PAYMENT"
  "WATING_FOR_START_LOTTERY" | "RUNNING_LOTTERY" | "FINISHED_LOTTERY"; // Use your exact enum values
  // isWin: boolean;
  tickets: Ticket[];
  lotteryRemainDate: string;
  id: string;
}

const ResultItem2: React.FC<ResultItem2Props> = ({
  date,
  luckyNumbers,
  // userChoosedNumbers,
  // userGuess,
  // amountWon,
  // numberOfCorrectGuesses,
  lotteryStatus,
  tickets,
  // isWin,
  lotteryRemainDate,
  id,
}) => {
  const navigate = useNavigate();

  function handleChooseNumber() {
    navigate("/fa/buy");
  }

  function handleIncreaseGuess() {
    navigate("/fa/buy/choosed-number");
  }

  const guessLabels = [
    t("result.first_guess"),
    t("result.second_guess"),
    t("result.third_guess"),
    t("result.fourth_guess"),
    t("result.fifth_guess"),
    t("result.sixth_guess"),
    t("result.seventh_guess"),
    t("result.eighth_guess"),
    t("result.ninth_guess"),
    t("result.tenth_guess"),
  ];

  return (
    <Paper
      elevation={2}
      sx={{
        background:
          // "linear-gradient(90deg, rgba(229, 66, 255, 0.8) 0%, rgba(137, 40, 153, 0.8) 100%)",
          "#0000000D",
        padding: 0,
        borderRadius: 4,
        maxWidth: "95%",
        margin: "16px auto",
      }}
    >
      <Box
        display={"flex"}
        sx={{
          background: "#ffffff38",
          // backgroundColor: `${
          //   lotteryStatus === "FINISHED_LOTTERY" && isWin //success
          //     ? "rgba(0, 158, 16, 1)"
          //     : !isWin && lotteryStatus === "FINISHED_LOTTERY" //danger
          //     ? "rgba(209, 0, 0, 1)"
          //     : lotteryStatus === "WATING_FOR_START_LOTTERY" || lotteryStatus === "WAITING_FOR_PAYMENT" //warning
          //     ? "rgba(255, 153, 0, 1)"
          //     : ""
          // }`,
          color: "white",
          padding: 1,
          textAlign: "center",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}
      >
        <YTypoGraphy variant="h6" fontSize={"0.875rem"} mr={1} mt={1}>
          {lotteryStatus === "FINISHED_LOTTERY"
            ? t("result.lucky_you_won")
            : // :lotteryStatus === "FINISHED_LOTTERY"
            // ?  t('result.this_time_luck_was_not_on_your_side')
            lotteryStatus === "WATING_FOR_START_LOTTERY"
            ? t("result.Its_not_time_yet")
            : //  lotteryStatus === "WAITING_FOR_PAYMENT"
              // ?  t('result.pending_payment')
              ""}
        </YTypoGraphy>
        <YTypoGraphy
          variant="subtitle1"
          textAlign={"left"}
          sx={{ flexGrow: 1, marginLeft: "8px" }}
        >
          {t("date")} {date}
        </YTypoGraphy>
      </Box>
      <Box sx={{ padding: 1 }}>
        <Card
          sx={{
            backgroundColor: "rgba(239, 239, 239, 1)",
            width: "264px",
            textAlign: "center",
            margin: "8px auto",
            display: "block",
            borderRadius: "16px",
          }}
        >
          <YTypoGraphy
            key={"item-parent-" + id}
            variant="h5"
            fontSize={"1rem"}
            mt={2}
          >
            {t("guess_numbers")}
          </YTypoGraphy>
          <Grid key={"item-" + id} container spacing={1} p={1}>
            {luckyNumbers
              .slice()
              .reverse()
              .map((number, index) => (
                <Grid item>
                  <ResultItemNumber
                    luckyNumbers={luckyNumbers}
                    userGuess={[]}
                    defaultColor={"rgba(47, 47, 47, 1)"}
                    number={number}
                    index={index}
                    isBlur={lotteryStatus === "WATING_FOR_START_LOTTERY"}
                  />
                </Grid>
              ))}
          </Grid>
        </Card>

        <Grid
          container
          spacing={1}
          direction={i18n.language === "en" ? "row-reverse" : "row"}
        >
          {tickets.map((item, index) => (
            <React.Fragment key={index}>
              <Grid item xs={3}>
                <YTypoGraphy
                  variant="h6"
                  color="white"
                  mt={3}
                  mr={1}
                  fontSize="0.875rem !important"
                  dir={i18n.language === "fa" ? "rtl" : "ltr"}
                >
                  {guessLabels[index] || guessLabels[0]}:
                </YTypoGraphy>
              </Grid>

              <Grid item xs={9} textAlign="left">
                <Grid container spacing={1} mt={0}>
                  {[
                    item.number_1,
                    item.number_2,
                    item.number_3,
                    item.number_4,
                    item.number_5,
                    item.number_6,
                  ]
                    ?.slice()
                    .reverse()
                    .map((number, idx) => (
                      <Grid item key={idx}>
                        <ResultItemNumber
                          luckyNumbers={[
                            item.number_1,
                            item.number_2,
                            item.number_3,
                            item.number_4,
                            item.number_5,
                            item.number_6,
                          ]}
                          userGuess={item.numbers_wins}
                          number={number}
                          index={idx}
                          isBlur={false}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>

      
      </Box>
    </Paper>
  );
};

export default ResultItem2;
