import React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';

type YTypoGraphyProps = TypographyProps & {
  lang?: string;
  color?: string;
  m?: string | number;
  p?: string | number;
};

// Utility function to detect Latin characters
const containsLatin = (text: React.ReactNode) => {
  return typeof text === 'string' && /[A-Za-z]/.test(text);
};

// Check if the content is a number
const isNumeric = (value: React.ReactNode) => {
  return typeof value === 'string' && !isNaN(Number(value));
};

const YTypoGraphy: React.FC<YTypoGraphyProps> = ({
  children,
  lang = 'fa',
  color,
  m,
  p,
  variant = 'body1', // Default variant is body1
  ...typographyProps
}) => {
  let fontFamily = "'Poppins', sans-serif"; // Default font family for non-Persian, numeric, and Latin characters
  let fontSize = 'inherit'; // Default font size

  // Check if the content contains Latin characters or is numeric
  if (lang === 'fa' && !containsLatin(children) && !isNumeric(children)) {
    fontFamily = "'Yekan Bakh', sans-serif"; // Use Yekan Bakh font for Persian language content (except numbers and Latin)
  }

  // Set standard size for headings
  if (variant.startsWith('h')) {
    switch (variant) {
      case 'h1':
        fontSize = '2.5rem';
        break;
      case 'h2':
        fontSize = '2rem';
        break;
      case 'h3':
        fontSize = '1.75rem';
        break;
      case 'h4':
        fontSize = '1.5rem';
        break;
      case 'h5':
        fontSize = '1.25rem';
        break;
      case 'h6':
        fontSize = '1rem';
        break;
      default:
        fontSize = 'inherit';
        break;
    }
  }

  return (
    <Typography
      variant={variant}
      style={{ fontFamily, fontSize, margin: m, padding: p, color }} // Added color to the style
      {...typographyProps}
    >
      {children}
    </Typography>
  );
};

export default YTypoGraphy;
