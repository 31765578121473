import { Box, Grid, Paper } from "@mui/material";
import "./GuessResultItem.css";
import { t } from "i18next";
import YTypoGraphy from "../../../../Text/YTypography/YTypoGraphy";
import PrizeCircle from "../../PrizeCircle/PrizeCircle";
import PrizePersianCurrency from "../../PrizePersianCurrency/PrizePersianCurrency";
import { Winner } from "../../../../../pages/Lottery/AllResulsts/page";

interface GuessResultItemProps {
  count?: number;
  numbers?: number;
  emails?: Winner[]; // New prop for emails
  mt?: number;
  mr?: number | string;
  mb?: number;
  ml?: number;
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
  m?: number;
  p?: number;
}

const GuessResultItem = ({
  count = 1,
  numbers = 0,
  emails = [], // Default to an empty array
  mt,
  mr,
  mb,
  ml, // margin
  pt,
  pr,
  pb,
  pl, // padding
  m,
  p, // overall margin and padding
}: GuessResultItemProps): JSX.Element => {
  return (
    <>
      <section
        className="guessPrizeItemContainer"
        style={{
          marginTop: mt,
          marginRight: mr,
          marginBottom: mb,
          marginLeft: ml,
          paddingTop: pt,
          paddingRight: pr,
          paddingBottom: pb,
          paddingLeft: pl,
          margin: m,
          padding: p,
        }}
      >
        <Grid container spacing={{ xs: 6 }} pt={1} pb={1} pr={3} pl={3}>
          <Grid item xs={6}>
            <PrizeCircle key={0} count={count} mt={1} />
          </Grid>
          <Grid item xs={6}>
            <YTypoGraphy color={"white"} textAlign={"left"} mt={1}>
              {numbers !== 0 ? `${numbers} ${t("person")}` : t("no_one")}
            </YTypoGraphy>
            {/* Render emails */}
          </Grid>
        </Grid>

        {emails.length > 0 && (
          <>
            <hr className="seperator" />
            <div style={{ maxHeight: "100px", overflowY: "scroll",marginBottom:'4px' }}>
              {emails.map((item, index) => (
                <div
                  key={index}
                  className="flex"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                 
                  <YTypoGraphy
                    variant="body2"
                    className="winner-row"
                    textAlign="right"
                    fontSize={"0.8rem !important"}
                    pt={0.7}
                  >
                    {index + 1}
                  </YTypoGraphy>

                  <YTypoGraphy
                    variant="body2"
                    className="winner-emails"
                    textAlign="left"
                    fontSize={"0.8rem !important"}
                    pt={0.7}
                  >
                    {item.email}
                  </YTypoGraphy>
                </div>
              ))}
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default GuessResultItem;
