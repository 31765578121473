import { Button } from "@mui/base";
import React from "react";
import "./FilterSvg.css";

interface FilterSvgProps {
  dir: string;
  float: string;
  color?: string;
}
// { title, align, onClick }
// FilterSvgProps
const FilterSvg: React.FC<FilterSvgProps> = ({
  dir,
  float,
  color = "none",
}) => {
  return (
    <div dir={dir} className={` ${float}`}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color == "none" ? "none" : color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_37_6915"
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="20"
          height="20"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.00049 2H21.5001V21.5007H2.00049V2Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_37_6915)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.77349 11.5985C8.79149 11.6145 8.80849 11.6295 8.82549 11.6475C9.90449 12.7535 10.4995 14.2185 10.4995 15.7735V19.7575L12.7355 18.5395C12.9115 18.4435 13.0205 18.2555 13.0205 18.0485V15.7615C13.0205 14.2125 13.6095 12.7525 14.6785 11.6525L19.5155 6.50751C19.8285 6.17451 20.0005 5.73751 20.0005 5.27651V4.34051C20.0005 3.87651 19.6345 3.49951 19.1865 3.49951H4.31549C3.86649 3.49951 3.50049 3.87651 3.50049 4.34051V5.27651C3.50049 5.73751 3.67249 6.17451 3.98549 6.50651L8.77349 11.5985ZM10.1465 21.5005C9.94449 21.5005 9.74449 21.4465 9.56249 21.3385C9.21049 21.1285 8.99949 20.7575 8.99949 20.3455V15.7735C8.99949 14.6385 8.57649 13.5695 7.80549 12.7505C7.78249 12.7315 7.75949 12.7105 7.73949 12.6885L2.89349 7.53551C2.31749 6.92351 2.00049 6.12051 2.00049 5.27651V4.34051C2.00049 3.04951 3.03949 1.99951 4.31549 1.99951H19.1865C20.4615 1.99951 21.5005 3.04951 21.5005 4.34051V5.27651C21.5005 6.11951 21.1835 6.92151 20.6095 7.53451L15.7625 12.6885C14.9595 13.5165 14.5205 14.6055 14.5205 15.7615V18.0485C14.5205 18.8045 14.1115 19.4965 13.4535 19.8565L10.6925 21.3605C10.5205 21.4535 10.3335 21.5005 10.1465 21.5005Z"
            fill={color == "none" ? "black" : color}
          />
        </g>
      </svg>
    </div>
  );
};

export default FilterSvg;
