import React, { useState } from "react";
import {
  Modal,
  Slide,
  Box,
  List,
  ListItem,
  Avatar,
  ListItemText,
  Divider,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import YButton from "../../../components/Button/YButton";
import YTextField from "../../../components/TextField/YTextField";
import {
  LotteryResultsResponse,
  WinsMember,
} from "../../../services/resultsService";
import WinnerListItem from "../../../components/Results/WinnerListItem/WinnerListItem";

interface AuthBottomSheetProps {
  handleUpdate: () => void;
  data: LotteryResultsResponse; // Assuming LotteryResultsResponse contains an array of winners
}

const WinnersBottomSheet: React.FC<AuthBottomSheetProps> = ({
  handleUpdate,
  data,
}) => {
  const [formState, setFormState] = useState({
    password: "",
    confirm_password: "",
  });

  const [winTab, setWinTab] = useState<number>(3);

  function Update() {
    handleUpdate();
    console.log(formState);
  }

  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          <YButton
            mt={2}
            color={winTab == 3 ? "secondary" : "primary-gray2"}
            align={"center"}
            radius={"radius-primary"}
            width={"116px"}
            height={48}
            mb={0.5}
            title="3/6"
            onClick={() => setWinTab(3)}
          />
        </Grid>

        <Grid item xs={4}>
          <YButton
            mt={2}
            color={winTab == 4 ? "secondary" : "primary-gray2"}
            align={"center"}
            radius={"radius-primary"}
            width={"116px"}
            height={48}
            mb={0.5}
            title="4/5"
            onClick={() => setWinTab(4)}
          />
        </Grid>

        <Grid item xs={4}>
          <YButton
            mt={2}
            color={winTab == 5 ? "secondary" : "primary-gray2"}
            align={"center"}
            radius={"radius-primary"}
            width={"116px"}
            height={48}
            mb={0.5}
            title="5/6"
            onClick={() => setWinTab(5)}
          />
        </Grid>
      </Grid>

      {winTab == 3 ? (
        <List sx={{ height: "60vh", overflowY: "auto" }}>
          {data.wins3.map((winner, index) => (
            <React.Fragment key={index}>
              <WinnerListItem index={index} winner={winner} />
              <Divider sx={{ backgroundColor: "rgba(105, 105, 105, 1)" }} />
            </React.Fragment>
          ))}
        </List>
      ) : null}

      {winTab == 4 ? (
        <List sx={{ height: "60vh", overflowY: "auto" }}>
          {data.wins4.map((winner, index) => (
            <>
              <WinnerListItem index={index} winner={winner} />

              <Divider sx={{ backgroundColor: "rgba(105, 105, 105, 1)" }} />
            </>
          ))}
        </List>
      ) : null}

      {winTab == 5 ? (
        <List sx={{ height: "60vh", overflowY: "auto" }}>
          {data.wins5.map((winner, index) => (
            <>
              <WinnerListItem index={index} winner={winner} />

              <Divider sx={{ backgroundColor: "rgba(105, 105, 105, 1)" }} />
            </>
          ))}
        </List>
      ) : null}
    </>
  );
};

export default WinnersBottomSheet;
