import React, { useState } from "react";
import { Modal, Slide,  Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import YButton from "../../../components/Button/YButton";
import YTextField from "../../../components/TextField/YTextField";
import { ProfileData } from "../../../interfaces/auth/profileData";
import { t } from "i18next";

interface FilterBottomSheetProps {
  handleUpdate: () => void;
}

const FilterBottomSheet: React.FC<FilterBottomSheetProps> = ({
  handleUpdate,
}) => {
  const [formState, setFormState] = useState<ProfileData>({
    gender: "",
    email: "",
    phone: "",
    country: "",
  });

  function Update() {
    handleUpdate();
    console.log(formState);
  }

  return (
    <>
      <YTextField
        label={t('gender')}
        fullWidth
        select
        value={formState.gender}
        setValue={(value: string) =>
          setFormState((prevState) => ({ ...prevState, gender: value }))
        }
        options={[
          { value: "male", label: t('male') },
          { value: "female", label: t('female') },
        ]}
        SelectProps={{
          IconComponent: ExpandMoreIcon,
        }}
      />

      <YTextField
        label={t('date')}
        value={formState.phone}
        setValue={(value: string) =>
          setFormState((prevState) => ({ ...prevState, phone: value }))
        }
        type="date"
      />

      <YTextField
        label={t('lottery.prize_number')}
        value={formState.email}
        setValue={(value: string) =>
          setFormState((prevState) => ({ ...prevState, email: value }))
        }
        type="tel"
      />
      <YTextField
        label={t('lottery.number_of_guess')}
        value={formState.email}
        setValue={(value: string) =>
          setFormState((prevState) => ({ ...prevState, email: value }))
        }
        type="tel"
      />

      <YButton
        title={t('apply')}
        color="secondary"
        width={'85vw'}
        height={50}
        border="#000 solid"
        onClick={Update}
      />
    </>
  );
};

export default FilterBottomSheet;
