// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lottery-value-container{
    box-sizing: border-box;
    width: 90vw;
    height: 143px;
    margin-right: 2.5vw;
    background: linear-gradient(180deg, rgba(229, 66, 255, 0.5) 0%, rgba(137, 40, 153, 0.5) 100%);
    -webkit-backdrop-filter: blur(50px);
            backdrop-filter: blur(50px);
    border: #ffa3f6 1px solid;
    border-radius: 8px; /* Optional: adds rounded corners */
    padding: 2px; /* Adjust as needed */
}

.lottery-value-container hr{
    background: linear-gradient(180deg, rgba(229, 66, 255, 0.5) 0%, rgba(137, 40, 153, 0.5) 100%);
    height: 1px;
    width: 92vw;
}`, "",{"version":3,"sources":["webpack://./src/components/Numbers/TopLotteryValue/TopLotteryValue.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,6FAA6F;IAC7F,mCAA2B;YAA3B,2BAA2B;IAE3B,yBAAyB;IACzB,kBAAkB,EAAE,mCAAmC;IACvD,YAAY,EAAE,qBAAqB;AACvC;;AAEA;IACI,6FAA6F;IAC7F,WAAW;IACX,WAAW;AACf","sourcesContent":[".lottery-value-container{\n    box-sizing: border-box;\n    width: 90vw;\n    height: 143px;\n    margin-right: 2.5vw;\n    background: linear-gradient(180deg, rgba(229, 66, 255, 0.5) 0%, rgba(137, 40, 153, 0.5) 100%);\n    backdrop-filter: blur(50px);\n    border-radius: 11px;\n    border: #ffa3f6 1px solid;\n    border-radius: 8px; /* Optional: adds rounded corners */\n    padding: 2px; /* Adjust as needed */\n}\n\n.lottery-value-container hr{\n    background: linear-gradient(180deg, rgba(229, 66, 255, 0.5) 0%, rgba(137, 40, 153, 0.5) 100%);\n    height: 1px;\n    width: 92vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
