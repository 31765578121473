import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import InfoSvg from "../../../components/SVG/InfoSvg/InfoSvg";
import DeleteSvg from "../../../components/SVG/DeleteSvg/DeleteSvg";
import YButton from "../../../components/Button/YButton";
import EditSvg from "../../../components/SVG/EditSvg/EditSvg";
import { Box } from "@mui/material";
import { deleteChoosedNumber } from "../../../services/lotteryService";
import YTypoGraphy from "../../../components/Text/YTypography/YTypoGraphy";
import { t } from "i18next";

interface ChooseNumberProps {
  number_id: number;
  navigateRoute: (path: string) => void;
  numbers: number[];
  mb?: number | string; // margin bottom prop
  mt?: number | string; // margin top prop
  mr?: number | string; // margin right prop
  ml?: number | string; // margin left prop
}

const ChoosedNumberComponent: React.FC<ChooseNumberProps> = ({
  number_id,
  navigateRoute,
  numbers,
  mb,
  mt,
  mr,
  ml,
}) => {
  const handleDeleteChoosedNumber = async () => {
    await deleteChoosedNumber(number_id);
    navigateRoute("/fa/buy");
  };

  const handleEditChoosedNumber = () => {
    localStorage.setItem("edit_number_id", number_id.toString());
    navigateRoute("/fa/buy/edit");
  };

  return (
    <>
      <Box ml={ml} mt={mt} mr={mr} mb={mb}>
        <Card className="card-choosed">
          <CardContent>
            <Grid
              container
              pl={4}
              pr={4}
              pt={0}
              spacing={4}
              direction={"row-reverse"}
            >
              {numbers.map((number, index) => (
                <Grid item xs={2}>
                  <YTypoGraphy
                    color={"rgba(22, 95, 238, 1)"}
                    variant="h4"
                    fontWeight={800}
                    fontSize={"1.5rem"}
                    mt={1}
                    textAlign={"center"}
                  >
                    {number}
                  </YTypoGraphy>
                </Grid>
              ))}
            </Grid>

            <Grid container spacing={1} borderRadius={8} mt={0}>
              <Grid item xs={6}>
                <YButton
                  title={t("edit")}
                  color={"edit"}
                  align={"center"}
                  radius={"radius-primary"}
                  width={140}
                  height={44}
                  mb={0.5}
                  onClick={handleEditChoosedNumber}
                  iconPosition="right"
                  icon={<EditSvg dir={""} float={""} />}
                />
              </Grid>

              <Grid item xs={6}>
                <YButton
                  title={t("delete")}
                  color={"delete"}
                  align={"center"}
                  radius={"radius-primary"}
                  width={140}
                  height={44}
                  onClick={handleDeleteChoosedNumber}
                  mb={0.5}
                  iconPosition="right"
                  icon={<DeleteSvg dir={""} float={""} />}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default ChoosedNumberComponent;
