import React from "react";
import { Grid }  from "@mui/material";
import "./GuessLotteryCard.css";
import PrizeCircle from "./../PrizeCircle/PrizeCircle";
import PrizePersianCurrency from "./../PrizePersianCurrency/PrizePersianCurrency";
import GreenLightText from "../../../Text/GreenLightText/GreenLightText";
import LeftSideSvg from "../../../SVG/LeftSideSvg/LeftSideSvg";
import YTypoGraphy from "../../../Text/YTypography/YTypoGraphy";

interface GuessLotteryCardProps {
  count: number;
  amount: number;
  choosedNumber: number;
  isDoingLottery: boolean;
  isShowWins: boolean;
}

const GuessLotteryCard: React.FC<GuessLotteryCardProps> = ({
  count,
  amount,
  choosedNumber,
  isDoingLottery,
  isShowWins,
}) => {
  return (
    <section className="guessLotteryContainer">
      <div className="content">
        <Grid container spacing={{ xs: 2 }} mt={2} pt={0} pb={2} pr={2} pl={2}>
          <Grid item xs={4}>
            <PrizeCircle count={count} mt={2} />
          </Grid>
          <Grid item xs={4}>
            <PrizePersianCurrency amount={amount} hasDollar={true} align="center" mt={1} />
          </Grid>
          <Grid item xs={4}>
            <Grid pt={1.5}>
              {isDoingLottery ? (
                <>
                  <GreenLightText
                    title={"درحال قرعه کشی"}
                    isLightOn={true}
                    isHeader={false}
                    fontSize={'0.65rem !important'}
                  />
                </>
              ) : (
                <>
                  <YTypoGraphy
                    variant="h6"
                    color="white"
                    textAlign="right"
                    fontSize={choosedNumber === 0 ? 13 : 22}
                    pr={3}
                    pt={0.5}
                  >
                    {(choosedNumber === 0&&!isShowWins) ? ""
                      : (choosedNumber === 0&&isShowWins) ? "هیچکی "
                      :isShowWins? `${choosedNumber} نفر`:''}
                  </YTypoGraphy>
                  {/* <LeftSideSvg dir="ltr" float="left" /> */}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default GuessLotteryCard;
