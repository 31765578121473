import React from "react";
import "./NotifyAlert.css";
import { Box, Grid, }  from "@mui/material";
import NotifyLight from "./NotifyLight";
import YTypoGraphy from "../Text/YTypography/YTypoGraphy";

interface NotifyAlertProps {
  dir?: string;
  float?: string;
  content: string;
  color?: "primary" | "success" | "danger" | null;
}

const NotifyAlert: React.FC<NotifyAlertProps> = ({
  dir,
  float,
  content,
  color,
}) => {
  return (
    <Grid className="NotifyAlert-container">
      <NotifyLight dir={""} float={""} color={color}/>
      <div dir={dir} className={`NotifyAlert ${float}`}>
        <svg
          width="49"
          height="48"
          viewBox="0 0 49 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="49"
            height="48"
            rx="8"
            fill={`${
              color == "primary"
                ? "#165FEE"
                : color == "success"
                ? "green"
                : color == "danger"
                ? "red"
                : ""
            }`}
          />
          <path
            d="M32 19L22 29L17 24"
            stroke="white"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <Box
          className="content"
          style={{ borderRadius: "0px", backgroundColor: "#fff" }}
        >
          <Grid p={0.5}>
          {/* fontSize={"0.875rem"} */}
            <YTypoGraphy pr={1} fontSize={"1.1rem"} >
              {content}
            </YTypoGraphy>
          </Grid>
        </Box>
      </div>
    </Grid>
  );
};

export default NotifyAlert;
